import { AnimatePresence, motion } from "framer-motion";
import React, { useState, useEffect } from "react";
import { BsChevronDown } from "react-icons/bs";
import { NavLink } from "react-router-dom";
const menuAnimation = {
  hidden: {
    opacity: 0,
    height: 0,
    padding: 0,
    transition: { duration: 0.3, when: "afterChildren" },
  },
  show: {
    opacity: 1,
    height: "auto",
    transition: {
      duration: 0.3,
      when: "beforeChildren",
    },
  },
};
// const menuItemAnimation = {
//   hidden: (i) => ({
//     x: "-100%",
//     transition: {
//       duration: (i + 1) * 0.1,
//     },
//   }),
//   show: (i) => ({
//     x: 0,
//     transition: {
//       duration: (i + 1) * 0.1,
//     },
//   }),
// };

const SidebarMenu = ({
  route,
  showAnimation,
  isBarOpen,
  isBarHover,
  setIsBarOpen,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    // setIsBarOpen(true);
  };

  useEffect(() => {
    if (!isBarOpen) {
      setIsMenuOpen(false);
    }
  }, [isBarOpen]);

  useEffect(() => {
    if (!isBarOpen && !isBarHover) {
      setIsMenuOpen(false);
    }
    // eslint-disable-next-line
  }, [isBarOpen, isBarHover]);
  
  return (
    <>
      <li className="submenuli">
        <button type="button" onClick={toggleMenu}>
          <div className="menuicon">{route.icon}</div>
          <AnimatePresence>
            {(isBarOpen || isBarHover) && (
              <motion.div
                variants={showAnimation}
                initial="hidden"
                animate="show"
                exit="hidden"
                className="menutext"
              >
                {route.name}
              </motion.div>
            )}
          </AnimatePresence>

          {(isBarOpen || isBarHover) && (
            <motion.div
              animate={
                isMenuOpen
                  ? {
                      rotate: -90,
                    }
                  : { rotate: 0 }
              }
            >
              <BsChevronDown />
            </motion.div>
          )}
        </button>
        <AnimatePresence>
          {isMenuOpen && (
            <motion.ul
              variants={menuAnimation}
              initial="hidden"
              animate="show"
              exit="hidden"
              className="submenudropdown"
            >
              {route.subRoutes.map((subRoute, i) => (
                <li key={i}>
                  <NavLink to={subRoute.path}>
                    <div className="menuicon">{subRoute.icon}</div>
                    <AnimatePresence>
                      {(isBarOpen || isBarHover) && (
                        <motion.div
                          variants={showAnimation}
                          initial="hidden"
                          animate="show"
                          exit="hidden"
                          className="menutext"
                        >
                          {subRoute.name}
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </NavLink>
                </li>
              ))}
            </motion.ul>
          )}
        </AnimatePresence>
      </li>
    </>
  );
};

export default SidebarMenu;
