import React from "react";

const Footer = () => {
  return (
    <>
      <div className="adminfooter px-2">
        <p className="small">
      Copyright © {new Date().getFullYear()} NSDMA All Rights Reserved.
</p>
      </div>
    </>
  );
};

export default Footer;
