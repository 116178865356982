import React, { useEffect, useState } from "react";
import Api from "../../config/Api";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Status from "./Components/status.json";
import Loader from "../Components/Widget/Loader";
import Select from "react-select";
import DatePicker from "react-multi-date-picker";
import { useForm, Controller } from "react-hook-form";
import dayjs from "dayjs";
import Graph1 from "./Components/Graph1";
import Graph2 from "./Components/Graph2";
import Graph3 from "./Components/Graph3";
import Graph4 from "./Components/Graph4";
import Graph5 from "./Components/Graph5";
import Graph6 from "./Components/Graph6";

const GraphicalReport = () => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    getValues,
  } = useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [graphData, setGraphData] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [disasterList, setDisasterList] = useState([]);
  const [damageLevel1List, setDamageLevel1List] = useState([]);
  const [damageLevel2List, setDamageLevel2List] = useState([]);
  const [damageLevel3List, setDamageLevel3List] = useState([]);
  const [isThirdDropdownShow, setIsThirdDropdownShow] = useState(false);

  const style = {
    control: (base, state) => ({
      ...base,
      border: state.isFocused ? "1px solid #224b8b" : "1px solid #ddd",
      boxShadow: "none",
      backgroundColor: state.isFocused ? "#fff" : "#fff",
      "&:hover": {
        borderColor: "#224b8b",
      },
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected ? "#224b8b" : "",
      "&:hover": {
        color: "#fff",
        backgroundColor: "#224b8b",
      },
    }),
  };

  const SelectOptionGenerate = (data, label, value) => {
    return data
      ?.filter((d) => d.status === "Active")
      .map((item) => {
        return {
          label: item[label],
          value: item[value],
          ...item,
        };
      });
  };

  const level3Option = (data) => {
    return data
      ?.filter((d) => d.status === "Active")
      .map((item) => {
        return {
          label: !!item?.second_damage?.extent_unit
            ? `${item.name} (${item?.second_damage?.extent_unit})`
            : item.name,
          value: item.id,
          ...item,
        };
      });
  };
  //damage level 1
  const getlevel1 = async () => {
    try {
      setIsLoading(true);
      const res = await Api("first_damage_classification_get");
      if (res.data.status === "1") {
        setDamageLevel1List(
          SelectOptionGenerate(
            res.data.DamageClassificationData.reverse(),
            "title",
            "id"
          )
        );
        setIsLoading(false);
      } else {
        toast.error(res?.data?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsLoading(false);
      }
    } catch (error) {
      toast.error(error.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
  //damage level 1
  //damage level 2
  const getlevel2 = async (id) => {
    try {
      setIsLoading(true);
      const res = await Api(`second_damage_classification_get/${id}`);
      if (res.data.status === 1) {
        setDamageLevel2List(
          SelectOptionGenerate(
            res.data.DamageClassificationData,
            "damage_name",
            "id"
          )
        );
        setIsLoading(false);
        return true;
      } else {
        toast.error(res?.data?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsLoading(false);
        return false;
      }
    } catch (error) {
      toast.error(error.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
  //damage level 2
  //damage level 3
  const getlevel3 = async (id) => {
    setIsThirdDropdownShow(false);
    setValue("third_damage_classification_id", "");
    try {
      setIsLoading(true);
      const res = await Api(`third_damage_classification_get/${id}`);
      if (res.data.status === 1) {
        setDamageLevel3List(level3Option(res.data.data));
        if (res.data.data.length > 1) {
          setIsThirdDropdownShow(true);
        } else {
          let tempData = {
            label: res.data.data[0].name,
            value: res.data.data[0].id,
            ...res.data.data[0],
          };
          setValue("third_damage_classification_id", tempData);
        }
        setIsLoading(false);
        return true;
      } else {
        toast.error(res?.data?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsLoading(false);
        return false;
      }
    } catch (error) {
      toast.error("Something went wrong!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
  //damage level 3
  //getDisaster
  const getDisaster = async () => {
    try {
      setIsLoading(true);
      const res = await Api("nature_of_disaster_get");
      if (res.data.status === 1) {
        setDisasterList(
          SelectOptionGenerate(res.data.NatureOfDisaster, "title", "id")
        );
        setIsLoading(false);
      } else {
        toast.error(res?.data?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsLoading(false);
      }
    } catch (error) {
      toast.error(error.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
  //getDisaster

  //getDistrict
  const getDistrict = async () => {
    try {
      setIsLoading(true);
      const res = await Api("get-district");
      if (res.data.status === 1) {
        setDistrictList(res?.data?.district);
        setIsLoading(false);
      } else {
        toast.error(res?.data?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsLoading(false);
      }
    } catch (error) {
      toast.error(error.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
  //getDistrict

  //Application Get
  const fetchGraphicalReport = async (props) => {
    try {
      // setIsLoading(true);
      // const body = !!props
      //   ? Object.fromEntries(
      //       Object.entries(props).filter(([key, value]) => value)
      //     )
      //   : {};
      const body = {};
      const { data } = await Api("graphical_report", "POST", body, true);
      // console.log(data?.data);
      if (data.status === 1) {
        setGraphData(data?.data);
        setIsLoading(false);
        return true;
      } else {
        toast.error(data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsLoading(false);
        return false;
      }
    } catch (error) {
      console.log(error);
    }
  };
  //Application Get

  useEffect(() => {
    // getlevel1();
    // getDisaster();
    // getDistrict();
    fetchGraphicalReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const onSubmit = async (d) => {
  //   const body = {
  //     gender: d?.gender,
  //     status: d?.status,
  //     deceased: d?.deceased,
  //     nature_disaster: d?.nature_disaster,
  //     first_damage_classification_id: d?.first_damage_classification_id?.value,
  //     second_damage_classification_id:
  //       d?.second_damage_classification_id?.value,
  //     third_damage_classification_id: d?.third_damage_classification_id?.value,
  //     sanction_letter_number: d?.sanction_letter_number,
  //     district_id: d?.district_id,
  //     location_disaster: d?.location_disaster,
  //     pincode: d?.pincode,
  //     village: d?.village,
  //     city: d?.city,
  //     admin_circle: d?.admin_circle,
  //     police_station: d?.police_station,
  //     start_date: d?.start_date,
  //     end_date: d?.end_date,
  //   };
  //   fetchGraphicalReport(body);
  // };

  return (
    <>
      <div className="bg-white p-3 rounded p-3 shadow">
        <div className="mb-3">
          <div className="flex-grow-1 mb-2">
            <h5 className="text-dark">Graphical Report</h5>
          </div>
          {/* <div className="flex-grow-1">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row g-2">
                <div className="col-xl-3 col-sm-6">
                  <div className="">
                    <select
                      className="form-select"
                      {...register("gender")}
                      disabled={isLoading}
                    >
                      <option value="">Select Gender</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Others">Others</option>
                    </select>
                  </div>
                </div>
                <div className="col-xl-3 col-sm-6">
                  <div className="">
                    <select
                      className="form-select"
                      disabled={isLoading}
                      {...register("status")}
                    >
                      <option value="">All Status</option>
                      {Status?.map((item) => {
                        return (
                          <option key={item.value} value={item.value}>
                            {item.label}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-xl-3 col-sm-6">
                  <div className="">
                    <select
                      className="form-select"
                      {...register("deceased")}
                      disabled={isLoading}
                    >
                      <option value="">Select Deceased</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </div>
                </div>

                <div className="col-xl-3 col-sm-6">
                  <div className="">
                    <select
                      className="form-select"
                      disabled={isLoading}
                      {...register("nature_disaster")}
                    >
                      <option value="">Select Nature Of Disaster</option>
                      {disasterList
                        ?.filter((NatureOfDisaster) => {
                          return NatureOfDisaster.status === "Active";
                        })
                        .map((NatureOfDisaster) => {
                          return (
                            <option
                              value={NatureOfDisaster?.id}
                              key={NatureOfDisaster?.id}
                            >
                              {NatureOfDisaster?.title}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                <div className="col-xl-3 col-sm-6">
                  <Controller
                    control={control}
                    name="first_damage_classification_id"
                    onFocus={true}
                    render={({ field }) => (
                      <Select
                        {...field}
                        autoFocus={field.ref}
                        isDisabled={isLoading}
                        styles={style}
                        placeholder={"Select Item Damaged"}
                        options={damageLevel1List}
                        onChange={(e) => {
                          field.onChange(e);
                          getlevel2(e.value);
                          setValue("second_damage_classification_id", "");
                          setValue("third_damage_classification_id", "");
                          setIsThirdDropdownShow(false);
                          setDamageLevel3List([]);
                        }}
                      />
                    )}
                  />
                </div>
                <div className="col-xl-3 col-sm-6">
                  <Controller
                    control={control}
                    name="second_damage_classification_id"
                    render={({ field }) => (
                      <Select
                        {...field}
                        isDisabled={isLoading}
                        styles={style}
                        placeholder={"Select Category"}
                        options={damageLevel2List}
                        onChange={(e) => {
                          field.onChange(e);
                          getlevel3(e.value);
                        }}
                      />
                    )}
                  />
                </div>
                <div
                  className={`col-xl-3 col-sm-6  ${
                    isThirdDropdownShow ? "d-block" : "d-none"
                  }`}
                >
                  <Controller
                    control={control}
                    name="third_damage_classification_id"
                    render={({ field }) => (
                      <Select
                        {...field}
                        isDisabled={isLoading}
                        styles={style}
                        placeholder={"Select Sub Category"}
                        options={damageLevel3List}
                        onChange={(e) => {
                          field.onChange(e);
                        }}
                      />
                    )}
                  />
                </div>
                <div className="col-xl-3 col-sm-6">
                  <div className="">
                    <input
                      type="text"
                      placeholder="Sanction letter Number"
                      className="form-control"
                      disabled={isLoading}
                      {...register("sanction_letter_number")}
                    />
                  </div>
                </div>

                <div className="col-xl-3 col-sm-6">
                  <div className="">
                    <select
                      className="form-select"
                      disabled={isLoading}
                      {...register("district_id")}
                    >
                      <option value="">Select District</option>
                      {districtList
                        ?.filter((districts) => {
                          return districts.status === "Active";
                        })
                        .map((districts) => {
                          return (
                            <option value={districts?.id} key={districts?.id}>
                              {districts?.district_name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                <div className="col-xl-3 col-sm-6">
                  <div className="">
                    <input
                      type="text"
                      placeholder="Location of disaster"
                      className="form-control"
                      disabled={isLoading}
                      {...register("location_disaster")}
                    />
                  </div>
                </div>
                <div className="col-xl-3 col-sm-6">
                  <div className="">
                    <input
                      type="text"
                      placeholder="Pincode"
                      className="form-control"
                      disabled={isLoading}
                      {...register("pincode")}
                    />
                  </div>
                </div>
                <div className="col-xl-3 col-sm-6">
                  <div className="">
                    <input
                      type="text"
                      placeholder="Village/Colony/Ward"
                      className="form-control"
                      disabled={isLoading}
                      {...register("village")}
                    />
                  </div>
                </div>
                <div className="col-xl-3 col-sm-6">
                  <div className="">
                    <input
                      type="text"
                      placeholder="City/Town"
                      className="form-control"
                      disabled={isLoading}
                      {...register("city")}
                    />
                  </div>
                </div>
                <div className="col-xl-3 col-sm-6">
                  <div className="">
                    <input
                      type="text"
                      placeholder="Admin Circle/Sub-division"
                      className="form-control"
                      disabled={isLoading}
                      {...register("admin_circle")}
                    />
                  </div>
                </div>
                <div className="col-xl-3 col-sm-6">
                  <div className="">
                    <input
                      type="text"
                      placeholder="Police Station"
                      className="form-control"
                      disabled={isLoading}
                      {...register("police_station")}
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="">
                    <Controller
                      control={control}
                      name="start_date"
                      render={({ field }) => (
                        <DatePicker
                          {...field}
                          inputClass="form-control"
                          placeholder="From Date"
                          maxDate={new Date()}
                          format="YYYY-MM-DD"
                          editable={true}
                          value={field.value || ""}
                          onChange={(date) => {
                            field.onChange(date?.format?.("YYYY-MM-DD"));
                            // startDateChange(date?.format?.("YYYY-MM-DD"));
                          }}
                          disabled={isLoading}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="">
                    <Controller
                      control={control}
                      name="end_date"
                      render={({ field }) => (
                        <DatePicker
                          {...field}
                          inputClass="form-control"
                          placeholder="To Date"
                          maxDate={new Date()}
                          minDate={dayjs(watch("start_date"), "YYYY-MM-DD").$d}
                          format="YYYY-MM-DD"
                          editable={true}
                          onChange={(date) => {
                            field.onChange(date?.format?.("YYYY-MM-DD"));
                            // endDateChange(date?.format?.("YYYY-MM-DD"));
                          }}
                          disabled={isLoading}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="d-flex gap-2">
                    <button
                      className="btn btn-primary px-3"
                      type="submit"
                      disabled={watch("fields")?.length > 10}
                    >
                      Show
                    </button>
                  </div>
                </div>
                <div className="col-auto">
                  {watch("fields")?.length > 10 && (
                    <div className="alert py-2 mb-0 alert-danger fw-bold small">
                      <p>
                        The display and PDF download of data are limited to a
                        maximum of 10 columns at once.
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </form>
          </div> */}
        </div>
        <div>
          <div className="row g-3">
            <div className="col-md-6 col-xl-4">
              <Graph1 data={graphData?.gender_wise} />
            </div>
            <div className="col-md-6 col-xl-4">
              <Graph2 data={graphData?.status_wise} />
            </div>
            <div className="col-md-6 col-xl-4">
              <Graph3 data={graphData?.deceased_wise} />
            </div>{" "}
            <div className="col-md-6 col-xl-4">
              <Graph4 data={graphData?.district} />
            </div>
            <div className="col-md-6 col-xl-4">
              <Graph5 data={graphData?.naturedisaster} />
            </div>
            <div className="col-md-6 col-xl-4">
              <Graph6 data={graphData?.itemsdamage} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GraphicalReport;
