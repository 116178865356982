import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

export const authenticationSlice = createSlice({
  name: "authentication",
  initialState: {
    admin: Cookies.get("AdminCookie") ? Cookies.get("AdminCookie") : null,
    adminToken: Cookies.get("AdminToken") ? Cookies.get("AdminToken") : null,
    isAdmin: Cookies.get("AdminToken") ? true : false,
  },
  reducers: {
    setAuth: (state, action) => {
      let tempObj = { ...action.payload };
      delete tempObj.token;
      const data = JSON.stringify(tempObj);
      const token = JSON.stringify(action.payload.token);
      Cookies.set("AdminCookie", data, {
        expires: 1,
        path: "/",
        //httpOnly:true
      });
      Cookies.set("AdminToken", token, {
        expires: 1,
        path: "/",
        //httpOnly:true
      });
      state.admin = data;
      state.adminToken = token;
      state.isAdmin = token ? true : false;
    },
    removeAuth: (state) => {
      Cookies.remove("AdminCookie", {
        expires: new Date("May 16, 1970"),
        path: "/",
      });
      Cookies.remove("AdminToken", {
        expires: new Date("May 16, 1970"),
        path: "/",
      });
      state.admin = null;
      state.adminToken = null;
      state.isAdmin = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setAuth, removeAuth } = authenticationSlice.actions;
export default authenticationSlice.reducer;
