import React from "react";
import { Doughnut } from "react-chartjs-2";

const Graph2 = ({ data }) => {
  const data1 = {
    labels: [
      "Pending",
      "DPA Review",
      "Verified",
      "Nodal Officer Review",
      "Approved",
      "Rejected",
      "Final Approved",
      "Under Process",
      "Released",
    ],
    datasets: [
      {
        label: "",
        data: [
          data?.pending,
          data?.dpareview,
          data?.verified,
          data?.nodalofficerreview,
          data?.approved,
          data?.rejected,
          data?.finalapproved,
          data?.underprocess,
          data?.released,
        ],
        borderWidth: 1,
      },
    ],
  };
  const option = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      datalabels: { display: true, color: "#fff" },
      legend: {
        position: "bottom",
      },
      colors: {
        forceOverride: true,
      },
    },
  };

  return (
    <div className="bg-white p-3 rounded-3 border">
      <div className="mb-3">
        <h6 className="text-dark fw-bold">Status wise SDRF</h6>
      </div>
      <div className="chartbox mt-3">
        <Doughnut options={option} data={data1} />
      </div>
    </div>
  );
};

export default Graph2;
