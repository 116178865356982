import React, { useEffect, useState } from "react";
import Api from "../../../config/Api";
import { Doughnut } from "react-chartjs-2";

const Graph2 = () => {
  const [pieData, setPieData] = useState([]);

  const getGraph = async () => {
    const url = "piechart";
    const { data } = await Api(url);
    // console.log(data);
    if (data.status === 1) {
      setPieData(data.data);
    }
  };

  // const randomColor = () => {
  //   function c() {
  //     var hex = Math.floor(Math.random() * 256).toString(16);
  //     return ("0" + String(hex)).substr(-2); // pad with zero
  //   }
  //   return "#" + c() + c() + c();
  // };

  const pieLabels1 =
    pieData?.districts?.length &&
    pieData?.districts?.map((a) => a?.district_name);
  const pieCounts1 =
    pieData?.districts?.length && pieData?.districts?.map((a) => a?.districts);
  // const pie1colors =
  //   pieData?.districts?.length && pieData?.districts?.map((a) => randomColor());

  const object1 = pieData?.naturedisaster;
  var pieLabels2 = [];
  var pieCounts2 = [];
  // var pie2colors = [];
  if (object1 !== undefined) {
    for (const [key, value] of Object.entries(object1)) {
      // console.log(`${key}: ${value}`);
      pieLabels2.push(key);
      pieCounts2.push(value);
      // pie2colors.push(randomColor());
    }
  }

  const pieLabels3 =
    pieData?.itemsdamage?.length && pieData?.itemsdamage?.map((a) => a?.title);
  const pieCounts3 =
    pieData?.itemsdamage?.length &&
    pieData?.itemsdamage?.map((a) => a?.No_of_first_damage);
  // const pie3colors =
  //   pieData?.districts?.length && pieData?.districts?.map((a) => randomColor());

 
  const data1 = {
    labels: pieLabels1,
    datasets: [
      {
        label: "",
        data: pieCounts1,
        // backgroundColor: pie1colors,
        borderWidth: 1,
      },
    ],
  };

  const data2 = {
    labels: pieLabels2,

    datasets: [
      {
        label: "",
        data: pieCounts2,
        // backgroundColor: pie3colors,
        borderWidth: 1,
      },
    ],
  };


  const data3 = {
    labels: pieLabels3,

    datasets: [
      {
        label: "",
        data: pieCounts3,
        // backgroundColor: pie2colors,
        borderWidth: 1,
      },
    ],
  };

  const option = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      datalabels: { display: true, color: "#fff" },
      legend: {
        position: "bottom",
      },
      colors: {
        forceOverride: true
      },
    },
  };

  useEffect(() => {
    getGraph();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div>
        <div className="row g-3">
          <div className="col-lg-4">
            <div className="bg-white p-3 rounded-3">
              <div className="mb-3">
                <h6 className="text-dark fw-bold">District wise SDRF</h6>
              </div>
              <div className="chartbox mt-3">
                <Doughnut options={option} data={data1} />
              </div>
            </div>
          </div>
          
          <div className="col-lg-4">
            <div className="bg-white p-3 rounded-3">
              <div className="mb-3">
                <h6 className="text-dark fw-bold">
                  Nature of disaster wise SDRF
                </h6>
              </div>
              <div className="chartbox mt-3">
                <Doughnut options={option} data={data2} />
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="bg-white p-3 rounded-3">
              <div className="mb-3">
                <div className="">
                  <h6 className="text-dark fw-bold">Item Damaged wise SDRF</h6>
                </div>
              </div>
              <div className="chartbox mt-3">
                <Doughnut options={option} data={data3} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Graph2;
