import React, { useEffect } from "react";
import { loadProgressBar } from "axios-progress-bar";
import "axios-progress-bar/dist/nprogress.css";

const ApiLoader = () => {
  useEffect(() => {
    loadProgressBar();
  }, []);
  return <></>;
};

export default ApiLoader;
