import React, { useEffect, useState } from "react";
import UserIcon from "../../assets/images/user.png";
import { useDispatch, useSelector } from "react-redux";
import { removeAuth } from "../../Reducers/Authentication";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import { Dropdown } from "react-bootstrap";
import {
  FaCog,
  FaPowerOff,
  FaLock,
  FaChevronDown,
  FaMoon,
  FaSun,
} from "react-icons/fa";
import { HiMenuAlt2 } from "react-icons/hi";

const Header = ({ toggleBarBtn, islogo }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const admin = useSelector((state) => JSON.parse(state.authentication.admin));

  const handleLogout = () => {
    dispatch(removeAuth());
    navigate("/login");
  };

  // Theme Toggle
  const [isDark, setIsDark] = useState(
    localStorage.getItem("theme") === "dark" ? true : false
  );

  useEffect(() => {
    document
      .getElementsByTagName("HTML")[0]
      .setAttribute("data-theme", localStorage.getItem("theme"));
  }, []);

  const toggleThemeChange = () => {
    if (isDark === false) {
      localStorage.setItem("theme", "dark");
      document
        .getElementsByTagName("HTML")[0]
        .setAttribute("data-theme", localStorage.getItem("theme"));
      setIsDark(true);
    } else {
      localStorage.setItem("theme", "light");
      document
        .getElementsByTagName("HTML")[0]
        .setAttribute("data-theme", localStorage.getItem("theme"));
      setIsDark(false);
    }
  };
  // Theme Toggle

  return (
    <>
      <div className="adminheader">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            {islogo ? (
              <div className="logo py-2">
                <img src={logo} style={{ height: "50px" }} alt="logo" />
              </div>
            ) : (
              <>
                <div className="adminheadermenubtn">
                  <button type="button" onClick={toggleBarBtn}>
                    <HiMenuAlt2 />
                  </button>
                </div>
                <div className="logo py-2 d-md-none">
                  <img src={logo} style={{ height: "50px" }} alt="logo" />
                </div>
              </>
            )}

            {/* <div>
              <h6 className="m-0 text-primary">Nagaland State Disaster Management Information System</h6>
            </div> */}
            {/* <div className="adminlanguagechangediv">
              <div id="google_translate_element"></div>
            </div> */}
          </div>
          <div className="admintopright">
            <ul>
              <li>
                <div className="themetoggle">
                  <input
                    type="checkbox"
                    id="themecheckbox"
                    onChange={toggleThemeChange}
                    defaultChecked={isDark}
                  />
                  <label htmlFor="themecheckbox">
                    <FaMoon className="moon" />
                    <FaSun className="sun" />
                    <span></span>
                  </label>
                </div>
              </li>
              <li>
                <Dropdown className="admintoprightdropdown">
                  <Dropdown.Toggle
                    variant=""
                    bsPrefix="d-flex align-items-center"
                  >
                    <img src={UserIcon} className="user-img" alt="avatar" />
                    <div className="user-info px-lg-2 d-none d-lg-inline-block">
                      <p className="user-name mb-0">
                        {admin?.full_name
                          ? admin?.full_name
                          : admin?.center_name
                          ? admin?.center_name
                          : "Admin"}
                      </p>
                    </div>
                    <FaChevronDown className="d-none d-lg-inline-block" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu align="end">
                    <Dropdown.Item as={Link} to="/change-information">
                      <FaCog />
                      Change Information
                    </Dropdown.Item>
                    {/* <Dropdown.Item as={Link} to="/change-password">
                      <FaLock />
                      Change Password
                    </Dropdown.Item> */}
                    <Dropdown.Item as="button" onClick={handleLogout}>
                      <FaPowerOff />
                      Logout
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
