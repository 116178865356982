import React, { useState } from "react";
import StarRatings from "react-star-ratings";
import { useForm, Controller } from "react-hook-form";
import logoImg from "../../assets/images/logo.png";
import { useParams } from "react-router-dom";
import "./feedback.scss";
import { toast } from "react-toastify";
import Api from "../../config/Api";

const Feedback = () => {
  const params = useParams();
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const [isLoading, setIsLoading] = useState(false);
  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      const body = {
        application_id: params.id,
        ...data,
      };
      // console.log(body);

      const res = await Api("feedback", "POST", body);
      // console.log(res);
      if (res.data.status === 1) {
        toast.success("Feedback submitted successfully!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        reset();
        setIsLoading(false);
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <section className="bg-light feedbacksec">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-8 col-sm-10">
            <div className="bg-white p-3 p-lg-4 rounded shadow-sm">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row g-3">
                  <div className="col-12">
                    <div className="logobox text-center">
                      <img src={logoImg} alt="logo" />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="text-center mb-2">
                      <h4 className="text-dark">Share your feedback</h4>
                      <p>How would you rate your experience with us?</p>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="star_rating_box">
                      <Controller
                        control={control}
                        name="rating"
                        rules={{ required: true }}
                        render={({ field }) => {
                          return (
                            <StarRatings
                              {...field}
                              rating={field.value || 0}
                              starRatedColor="#224b8b"
                              starHoverColor="#3463ad"
                              starEmptyColor="#b9b9b9"
                              starDimension="25px"
                              changeRating={(a) => {
                                field.onChange(a);
                              }}
                              numberOfStars={5}
                            />
                          );
                        }}
                      />
                      {errors.rating && (
                        <div className="error_line">Rating is required</div>
                      )}
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="">
                      <textarea
                        {...register("feedback", {
                          required: true,
                        })}
                        className="form-control"
                        placeholder="How was your experience"
                        rows={5}
                        disabled={isLoading}
                      ></textarea>
                      {errors.feedback && (
                        <div className="error_line">Feedback is required</div>
                      )}
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="text-center">
                      <input
                        type="submit"
                        value={isLoading ? "Submitting..." : "Submit"}
                        className="btn btn-primary px-5"
                        disabled={isLoading}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Feedback;
