import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import dayjs from 'dayjs'

const Weather = () => {
  const [place, setPlace] = useState('Nagaland')
  const [tempData, setTempData] = useState('')
  const [forcastData, setForcastData] = useState('')

  const fetchData1 = async (city) => {
    try {

      const res = await fetch(`https://api.openweathermap.org/data/2.5/forecast?q=${city}&cnt=7&units=metric&appid=a6a0360da8cb0ff7b96c0dad8f6c2216`);
      const data = await res.json()
      if (data.cod === "200") {
        setForcastData(data)
      } else {
        toast.error(data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }

    } catch (error) {
      toast.error(error.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }

  const fetchData2 = async (city) => {
    try {

      const res = await fetch(`https://api.openweathermap.org/data/2.5/weather?q=${city}&units=metric&appid=a6a0360da8cb0ff7b96c0dad8f6c2216`);
      const data = await res.json()
      if (data.cod === 200) {
        setTempData(data)
      } else {
        toast.error(data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }

    } catch (error) {
      toast.error(error.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }

  const inputChange = (e) => {
    setPlace(e.target.value)
  }
  const submitCity = (e) => {
    e.preventDefault()
    fetchData1(place)
    fetchData2(place)
  }

  useEffect(() => {
    fetchData1('Nagaland')
    fetchData2('Nagaland')
  }, []);

  // useEffect(() => {
  //   console.log(forcastData);
  // }, [forcastData]);

  return (
    <div className="bg-white shadow-sm p-3">
      <div className="d-md-flex">
        <div className="flex-grow-1">
          <h5 className="text-dark">Weather</h5>
        </div>
        <div className="flex-shrink-0">
          <div className="d-sm-flex align-items-center">
            <div className="flex-grow-1 mb-1 mb-sm-0">
              <label className="m-0 form-label">Change Place :</label>
            </div>
            <div className="flex-shrink-0 ms-sm-3">
              <form onSubmit={submitCity}>
                <div className="input-group">
                  <input type="text" value={place} onChange={inputChange} className="form-control place_textbox" placeholder="Search place" />
                  <button className="input-group-text" type="submit">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-3">
        {
          tempData !== '' ? (
            <div className="bg-white shadow rounded-2 overflow-hidden">
              <div className="bg-warning p-3 ">
                <h5 className="text-dark m-0">Weather for {tempData?.name}, {tempData?.sys?.country}</h5>
              </div>
              <div className="p-3 d-md-flex">
                <div className="flex-shrink-0 me-md-3 mb-3 mb-md-0 text-center text-dark">
                  <div className="bg-light rounded-3 px-4 py-3">
                    <div className="">
                      <div className="h1 m-0 fw-bold"><img alt="weather" style={{ height: '70px' }} src={`https://openweathermap.org/themes/openweathermap/assets/vendor/owm/img/widgets/${tempData && tempData?.weather[0] && tempData?.weather[0]?.icon}.png`} />{tempData?.main?.temp.toFixed(0)}°C</div>
                      <div className="d-flex justify-content-center gap-3">
                        <span className="small">Min : {tempData?.main?.temp_min}</span>
                        <span className="small">Max : {tempData?.main?.temp_max}</span>
                      </div>
                    </div>
                    <div className="text-dark">
                      <div className="fw-bold">{tempData && tempData?.weather[0] && tempData?.weather[0]?.description}</div>
                      <div className="small">Wind: {tempData?.wind?.speed} m/s</div>
                    </div>
                  </div>

                </div>
                <div className="flex-grow-1">
                  <div>
                    <ul className="d-flex flex-wrap gap-3">
                      {
                        forcastData?.list?.map((a, i) => {
                          return (
                            <li className="flex-grow-1 bg-light rounded-2 p-3" key={i}>
                              <div className="fw-bold text-dark fs-5 mb-3"><img alt="weather" style={{ height: '30px' }} src={`https://openweathermap.org/themes/openweathermap/assets/vendor/owm/img/widgets/${a?.weather[0]?.icon}.png`} />{a?.main?.temp.toFixed(0)}°C</div>
                              <div className="small">Min : {a?.main?.temp_min}</div>
                              <div className="small">Max : {a?.main?.temp_max}</div>
                              <div className="small">{dayjs(a?.dt_txt).format('hh:mm a')}</div>
                              <div className="small">{dayjs(a?.dt_txt).format('DD MMM, YYYY')}</div>

                            </li>
                          )
                        })
                      }
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )
            : (
              <div>
                <p>Data not found</p>
              </div>
            )
        }

      </div>
    </div>
  );
};

export default Weather;
