import React, { useEffect, useState } from "react";
import Api from "../../config/Api";
import { useDispatch, useSelector } from "react-redux";
import { setAuth } from "../../Reducers/Authentication";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";

const Index = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const admin = useSelector((state) => JSON.parse(state.authentication.admin));
  const adminToken = useSelector((state) =>
    JSON.parse(state.authentication.adminToken)
  );

  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!!admin) {
      // console.log(admin);
      reset({
        full_name: admin.full_name,
        email_id: admin.email_id,
        mobile_no: admin.mobile_no,
        role: admin.role.role_name,
        state: admin.state.state_name,
        district:
          admin.role.role_name.toLowerCase().includes("admin") ||
          admin.role.role_name.toLowerCase().includes("hod") ||
          admin.role.role_name.toLowerCase().includes("relief")
            ? "All Districts"
            : admin.district.map(a => a.district_name).join(', '),
      });
    }
    // eslint-disable-next-line
  }, [reset]);

  const onSubmit = async (d) => {
    try {
      setIsLoading(true);
      const body = {
        full_name: d.full_name,
        email_id: d.email_id,
        mobile_no: d.mobile_no,
      };

      const res = await Api("change-info", "POST", body);
      // console.log(res);
      if (res.data.status === 1) {
        toast.success("Data Updated Successfully !", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        const objToken = {
          token: adminToken,
        };
        const newData = Object.assign(admin, res.data.UserDetails, objToken);
        // console.log(newData);
        dispatch(setAuth(newData));
        setIsLoading(false);
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsLoading(false);
      }
    } catch (error) {
      toast.error(error.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  return (
    <>
      <div className="bg-white p-3 rounded p-3 shadow">
        <div className="d-flex justify-content-between mb-3">
          <div className="flex-grow-1">
            <h5 className="text-dark">Change Information</h5>
          </div>
        </div>
        <div>
          <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
            <div className="row g-3">
              <div className="col-lg-3 col-sm-6 col-md-4">
                <label className="form-label">Role</label>
                <input
                  type="text"
                  className="form-control"
                  disabled={true}
                  {...register("role", { required: true })}
                />
                {errors.role && (
                  <div className="error_line">Role is required</div>
                )}
              </div>
              <div className="col-lg-3 col-sm-6 col-md-4">
                <label className="form-label">Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Name"
                  {...register("full_name", { required: true })}
                  disabled={isLoading}
                />
                {errors.full_name && (
                  <div className="error_line">Name is required</div>
                )}
              </div>

              <div className="col-lg-3 col-sm-6 col-md-4">
                <label className="form-label">Email</label>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Enter Email"
                  {...register("email_id", {
                    required: "Email is required",
                    pattern: {
                      value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                      message: "Invalid Email ID",
                    },
                  })}
                  disabled={isLoading}
                />
                {errors.email_id && (
                  <div className="error_line">{errors.email_id.message}</div>
                )}
              </div>

              <div className="col-lg-3 col-sm-6 col-md-4">
                <label className="form-label">Phone</label>
                <input
                  type="tel"
                  className="form-control"
                  placeholder="Enter Phone"
                  {...register("mobile_no", {
                    required: "Phone is required",
                    pattern: {
                      value: /^[6-9]\d{9}$/,
                      message: "Invalid Phone Number",
                    },
                  })}
                  disabled={isLoading}
                />
                {errors.mobile_no && (
                  <div className="error_line">{errors.mobile_no.message}</div>
                )}
              </div>

              <div className="col-lg-3 col-sm-6 col-md-4">
                <label className="form-label">State</label>
                <input
                  type="text"
                  className="form-control"
                  {...register("state", { required: true })}
                  disabled={true}
                />
              </div>

              <div className="col-lg-3 col-sm-6 col-md-4">
                <label className="form-label">District</label>
                <input
                  type="text"
                  className="form-control"
                  {...register("district", { required: true })}
                  disabled={true}
                />
              </div>

              <div className="col-lg-3 col-sm-6">
                <div>
                  <label className="form-label d-none d-lg-block"></label>
                  <input
                    type="submit"
                    value={`${
                      isLoading ? "Updating..." : "Update Information"
                    }`}
                    className="btn btn-primary"
                    disabled={isLoading}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Index;
