import axios from "axios";
import Cookies from "js-cookie";
const url = process.env.REACT_APP_API_URL;
const base_url = url;

let cancelToken;
const Api = async (endpoint, method, body, cancelTokenVar = false) => {
  const aTokenString = Cookies.get("AdminToken")
    ? Cookies.get("AdminToken")
    : "";
  const token = aTokenString ? JSON.parse(aTokenString) : "";
  let options = {
    url: base_url + endpoint,
    method: method || "GET",
    data: body,
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
    },
    responseType: "json",
  };

  if (cancelTokenVar) {
    //Check if there are any previous pending requests
    if (typeof cancelToken !== typeof undefined) {
      cancelToken.cancel("Operation canceled due to new request.");
    }

    //Save the cancel token for the current request
    cancelToken = axios.CancelToken.source();

    options.cancelToken = cancelToken.token;
  }

  if (method === "GET") {
    options.data = undefined;
  }
  // console.log('hit');
  return axios(options)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(`Error from ${endpoint} >> ${error}`);
      throw error.response;
    });
};

export default Api;
