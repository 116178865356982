import React, { useEffect, useState } from "react";
import Api from "../../config/Api";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { Modal } from "react-bootstrap";
import { BsPlusLg, BsFillPencilFill, BsFillTrashFill } from "react-icons/bs";
import GifImg from "../../assets/images/loader.gif";
import Pagination from "react-bootstrap/Pagination";
import TooltipWidget from "../Components/Widget/TooltipWidget";

const ItemDamaged = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const [damageList, setDamageList] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchItem, setSearchItem] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [damageUpdate, setDamageUpdate] = useState({
    id: "",
    isUpdating: false,
  });

  const damageModalClose = () => {
    reset({
      title: "",
      multiselect: "",
      status: "",
    });
    setModalShow(false);
    setDamageUpdate({ id: "", isUpdating: false });
  };

  const damageModalShow = () => setModalShow(true);

  //get users
  const getItemDamages = async (pageCount) => {
    // console.log(pageCount);
    try {
      const res = await Api(
        `first_damage_classification_get?page=${pageCount}`
      );
      // console.log(res);
      if (res.data.status === "1") {
        setDamageList(res.data.DamageClassificationData);
        setIsDataLoaded(true);
      } else {
        toast.error(res?.data?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    } catch (error) {
      toast.error(error.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
  //get users

  //Update User
  const editDamage = async (damageObj) => {
    setDamageUpdate({
      id: damageObj.id,
      isUpdating: true,
    });
    damageModalShow();
    reset({
      title: damageObj.title,
      // multiselect: damageObj.multiselect,
      status: damageObj.status,
    });
  };
  //Update User

  //Delete User
  const removeDamage = async (damageObj) => {
    try {
      if (damageObj) {
        if (window.confirm("Are you sure you want to delete?") === true) {
          const body = {
            id: damageObj.id,
          };
          const res = await Api(
            "first_damage_classification_delete",
            "POST",
            body
          );
          setIsDataLoaded(false);
          // console.log(res);
          if (res.data.status === "1") {
            toast.success("Item Damaged Deleted Successfully!", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });

            getItemDamages();
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        }
      }
    } catch (error) {
      toast.error(error.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
  //Delete User

  useEffect(() => {
    getItemDamages(1);
  }, []);

  //pagination count
  const IndexCount = ({ index }) => {
    let cp = damageList.current_page;
    let pp = damageList.per_page;
    let count;
    if (cp > 1) {
      count = (cp - 1) * pp + index;
    } else {
      count = index;
    }
    return count;
  };
  //pagination count

  //pagination
  const PaginationRow = () => {
    return (
      <div className="mt-4 d-flex justify-content-md-center overflow-auto">
        <Pagination>
          {damageList?.links?.map((item, i) => {
            if (i === 0) {
              return (
                <Pagination.Prev
                  key={i}
                  onClick={() => getItemDamages(damageList?.current_page - 1)}
                  disabled={damageList?.current_page === 1 ? true : false}
                />
              );
            } else if (item.label === "...") {
              return <Pagination.Ellipsis key={i} />;
            } else if (i + 1 === damageList?.links.length) {
              return (
                <Pagination.Next
                  key={i}
                  onClick={() => getItemDamages(damageList?.current_page + 1)}
                  disabled={
                    damageList?.current_page === damageList?.last_page
                      ? true
                      : false
                  }
                />
              );
            } else if (i !== damageList?.links.length) {
              return (
                <Pagination.Item
                  key={i}
                  active={Number(item.label) === damageList?.current_page}
                  onClick={() => getItemDamages(item.label)}
                >
                  {item?.label}
                </Pagination.Item>
              );
            } else {
              return false;
            }
          })}
        </Pagination>
      </div>
    );
  };
  //pagination

  const onSubmit = async (d) => {
    try {
      setIsLoading(true);
      // console.log(d);
      if (damageUpdate.isUpdating) {
        let body = {
          title: d.title,
          // multiselect: d.multiselect,
          status: d.status,
        };
        // console.log(body);
        const res = await Api(
          `first_damage_classification_update/${damageUpdate.id}`,
          "POST",
          body
        );
        // console.log(res);
        if (res.data.status === "1") {
          toast.success("Item Damaged Updated Successfully!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setDamageUpdate({
            id: "",
            isUpdating: false,
          });
          reset();
          damageModalClose();
          setIsDataLoaded(false);
          getItemDamages();
          setIsLoading(false);
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setIsLoading(false);
        }
      } else {
        let body = {
          title: d.title,
          // multiselect: d.multiselect,
          status: d.status,
        };
        const res = await Api("first_damage_classification_add", "POST", body);
        // console.log(res);
        if (res.data.status === "1") {
          toast.success("Item Damaged Added Successfully!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          reset();
          damageModalClose();
          setIsDataLoaded(false);
          getItemDamages();
          setIsLoading(false);
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setIsLoading(false);
        }
      }
    } catch (error) {
      toast.error(error.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  const handleInputChange = (e) => {
    setSearchItem(e.target.value);
  };

  const filteredItems = !searchItem
    ? damageList?.data
    : damageList?.data.filter((damage) =>
        damage?.title?.toLowerCase().includes(searchItem.toLowerCase())
      );

  return (
    <>
      <div className="bg-white p-3 rounded p-3 shadow">
        <div className="d-flex flex-wrap justify-content-between mb-3">
          <div className="flex-grow-1">
            <h5 className="text-dark">Item Damaged</h5>
          </div>
          <div className="">
            <div className="d-flex flex-wrap gap-2 align-items-center">
              <div>
                <input
                  type="text"
                  value={searchItem}
                  onChange={handleInputChange}
                  placeholder="Type to search"
                  className="form-control"
                />
              </div>
              <div>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={damageModalShow}
                >
                  <BsPlusLg className="me-2" />
                  Add Item Damaged
                </button>
              </div>
            </div>
          </div>
        </div>
        {isDataLoaded ? (
          filteredItems?.length > 0 ? (
            <div className="table-responsive">
              <table className="table table-bordered m-0">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Item Damaged</th>
                    {/* <th>Multiselect</th> */}
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredItems?.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td>
                          <IndexCount index={i + 1} />
                        </td>

                        <td>{item?.title}</td>
                        {/* <td>
                          {item?.multiselect === "1"
                            ? "Yes"
                            : item?.multiselect === "2"
                            ? "No"
                            : "-"}
                        </td> */}
                        <td>
                          {item?.status === "Active" ? (
                            <span className="badge bg-success">Active</span>
                          ) : item.status === "Deactive" ? (
                            <span className="badge bg-danger">Deactive</span>
                          ) : (
                            ""
                          )}
                        </td>
                        <td>
                          <div className="d-flex gap-2 flex-wrap">
                            <TooltipWidget title="Edit">
                              <button
                                className="btn btn-primary btn-sm"
                                type="button"
                                onClick={() => editDamage(item)}
                              >
                                <BsFillPencilFill />
                              </button>
                            </TooltipWidget>
                            <TooltipWidget title="Delete">
                              <button
                                className="btn btn-danger btn-sm"
                                type="button"
                                onClick={() => removeDamage(item)}
                              >
                                <BsFillTrashFill />
                              </button>
                            </TooltipWidget>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              {damageList?.last_page > 1 && <PaginationRow />}
            </div>
          ) : (
            <div className="alert alert-danger m-0">
              <p>No Item Damaged Added</p>
            </div>
          )
        ) : (
          <div className="loaderbox">
            <img src={GifImg} alt="Loader" />
            <p>Loading...</p>
          </div>
        )}
      </div>

      <Modal
        show={modalShow}
        onHide={damageModalClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-dark">
            {damageUpdate.isUpdating ? "Edit" : "Add"} Item Damaged
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row g-3">
                <div className="col-sm-12">
                  <label className="form-label">
                    Item Damaged <span className="fw-bold text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    {...register("title", { required: true })}
                    disabled={isLoading}
                  />
                  {errors.title && (
                    <div className="error_line">
                      Damage Type Title is required
                    </div>
                  )}
                </div>
                {/* <div className="col-sm-6">
                  <label className="form-label">Multiselect</label>
                  <select
                    className="form-select"
                    {...register("multiselect", { required: true })}
                    disabled={isLoading}
                  >
                    <option value="">Select Multiselect</option>
                    <option value="1">Yes</option>
                    <option value="2">No</option>
                  </select>
                  {errors.multiselect && (
                    <div className="error_line">Multiselect is required</div>
                  )}
                </div> */}
                <div className="col-12">
                  <label className="form-label">
                    Status <span className="fw-bold text-danger">*</span>
                  </label>
                  <select
                    className="form-select"
                    {...register("status", { required: true })}
                    disabled={isLoading}
                  >
                    <option value="">Select Status</option>
                    <option value="Active">Active</option>
                    <option value="Deactive">Deactive</option>
                  </select>
                  {errors.status && (
                    <div className="error_line">Status is required</div>
                  )}
                </div>

                <div className="col-12">
                  <div>
                    <input
                      type="submit"
                      value={damageUpdate.isUpdating ? "Update" : "Submit"}
                      className="btn btn-primary"
                      disabled={isLoading}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ItemDamaged;
