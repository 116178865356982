import React, { useEffect, useContext } from "react";
import { useFormContext } from "react-hook-form";
// import Api from "../../../../config/Api";
// import { toast } from "react-toastify";
import { DamageContext } from "../Sdrf3";

const Authentications = (props) => {
  const {
    // control,
    register,
    formState: { errors },
    // watch,
    // setValue,
  } = useFormContext();

  const {
    // level1Data,
    // damage,
    index,
    // isLoading,
    // setIsLoading,
    // setIsFetching,
    isEdit,
  } = props;

  //deleteHandler
  const { remove } = useContext(DamageContext);
  const deleteHandler = (i) => {
    remove(i);
  };
  //deleteHandler

  useEffect(() => {
    if (isEdit) {
      (async () => {
        // let res1;
        // let res2;
        // if (!!damage?.first_damage_classification_id?.value) {
        //   res1 = await getlevel2(damage?.first_damage_classification_id?.value);
        // }
        // if (!!damage?.second_damage_classification_id?.value) {
        //   res2 = await getlevel3(damage?.second_damage_classification_id?.value);
        // }
        // if (res1 && res2) {
        //   setIsFetching(false);
        // }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

  return (
    <div className="damagerow_div">
      <div className="row g-2">
        <div className="col-sm-6 col-md-4 col-lg-5">
          <label className="form-label">
            Name
            <span className="text-danger fw-bold">*</span>
          </label>
          <input
            type="text"
            className="form-control"
            {...register(`authentications.${index}.name`, {
              required: true,
            })}
          />
          {errors &&
            errors.authentications &&
            errors.authentications?.[index]?.name && (
              <div className="error_line">Name is required</div>
            )}
        </div>
        <div className="col-sm-6 col-md-4 col-lg-5">
          <label className="form-label">
            Designation
            <span className="text-danger fw-bold">*</span>
          </label>
          <input
            type="text"
            className="form-control"
            {...register(`authentications.${index}.designation`, {
              required: true,
            })}
          />
          {errors &&
            errors.authentications &&
            errors.authentications?.[index]?.designation && (
              <div className="error_line">Designation is required</div>
            )}
        </div>

        {index > 0 && (
          <div className="col-sm-4 col-lg-2">
            <div className="">
              <label className="form-label"></label>
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => deleteHandler(index)}
              >
                Delete
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Authentications;
