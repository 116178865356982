import React  from "react";
import { Link } from "react-router-dom";
import { FiFileText, FiUsers, FiCloudSnow } from "react-icons/fi";

const Home = () => {
  return (
    <>
      <div className="">
        <div className="mb-3">
          <div className="row g-3 intermidiaterow">
            <div className="col-12 col-sm-6 col-md-4">
              <div className="button_box">
                <Link to="/dashboard">
                  <div className="icon_div">
                    <FiFileText />
                  </div>
                  <div className="name_div">
                    <p>SDRF Form</p>
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4">
              <div className="button_box">
                <Link to="/weather">
                  <div className="icon_div">
                    <FiCloudSnow />
                  </div>
                  <div className="name_div">
                    <p>Weather Forecast</p>
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="button_box">
                <Link to="/manage-user">
                  <div className="icon_div">
                    <FiUsers />
                  </div>
                  <div className="name_div">
                    <p>User</p>
                  </div>
                </Link>
              </div>
            </div>
            {/* <div className="col-6 col-md-3">
              <div className="button_box">
                <Link to="/dashboard">
                  <div className="icon_div">
                    <FiSettings />
                  </div>
                  <div className="name_div">
                    <p>Configuration</p>
                  </div>
                </Link>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
