import React, { useEffect, useState } from "react";
import Api from "../../../config/Api";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import GifImg from "../../../assets/images/loader.gif";
import { FaDownload } from "react-icons/fa";
import { useSelector } from "react-redux";
const SdrfDocuments = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    // reset,
  } = useForm();
  const admin = useSelector((state) => JSON.parse(state.authentication.admin));
  const location = useLocation();
  const { id } = location.state || {};
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [detailData, setDetailData] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState({
    passport: [],
    aadhar: [],
    certificate: [],
    signature: [],
    [`damages[]`]: [],
  });

  //setDataInEdit
  const getData = async (id) => {
    try {
      const body = {
        id,
      };
      const res = await Api("view_applicant_details", "POST", body);
      // console.log(res);
      if (res.data.status === 1) {
        setDetailData(res.data.ApplicantDetails?.Details?.upload_documents);
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsFetching(false);
      }
    } catch (error) {
      toast.error(error.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
  //setDataInEdit

  const uploadInputFiles = (e, type, size = 20480, length = 5) => {
    if (!!e.target.files.length) {
      const fname = (a) => (a.name ? a.name : "");
      const isTypeMatched = (b) => type.some((el) => fname(b).endsWith(el));
      const fileSize = (c) => Math.round(c.size / 1024);
      for (let x of e.target.files) {
        if (!isTypeMatched(x)) {
          toast.error("Invalid File Format", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          e.target.value = "";
          return;
        }

        if (fileSize(x) > size) {
          toast.error(`File size should be less then ${size}kb`, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          e.target.value = "";
          return;
        }
      }

      if (e.target.files.length > length) {
        toast.error(`Only ${length} images allowed`, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        e.target.value = "";
        return;
      }

      let files = [];
      for (let a of e.target.files) {
        files.push(a);
      }
      setSelectedFiles((prev) => ({ ...prev, [e.target.name]: files }));
    }
  };

  useEffect(() => {
    if (id) {
      getData(id);
    } else {
      navigate(-1);
    }
    // eslint-disable-next-line
  }, [id]);

  const onSubmit = async (d, e) => {
    try {
      // setIsLoading(true);
      if (!id) {
        toast.error("Application ID Invalid", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsLoading(false);
        return;
      }

      const formData = new FormData(e.target);
      formData.append("application_id", id);
      // formData.append("passport", d?.passport[0] ? d?.passport[0] : "");
      // formData.append("aadhar", d?.aadhar[0] ? d?.aadhar[0] : "");
      // formData.append(
      //   "certificate",
      //   d?.certificate[0] ? d?.certificate[0] : ""
      // );
      // formData.append("signature", d?.signature[0] ? d?.signature[0] : "");
      // for (let a of d?.damage_images) {
      //   formData.append("damage_images", a);
      // }

      // for(let a of formData.entries()){
      //   console.log(`${a[0]}, ${a[1]}`);
      // }

      // console.log(Object.fromEntries(formData));

      const res = await Api("upload_documents", "POST", formData);
      // console.log(res);

      if (res.data.status === 1) {
        setIsLoading(false);
        toast.success("Documents has been uploaded!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        // reset();
        if (
          admin?.role?.permission
            .map((word) => word.toLowerCase())
            .some((a) => a.includes("nodal"))
        ) {
          navigate("/verify-applications");
        } else {
          navigate("/manage-applications");
        }
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsLoading(false);
      }
    } catch (error) {
      toast.error(error.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  return (
    <>
      <div className={`loaderoverlay ${isFetching && "show"}`}>
        <div>
          <img src={GifImg} alt="loader" />
          <p>Loading...</p>
        </div>
      </div>
      <div className="bg-white p-3 rounded p-3 shadow">
        <div className="d-flex justify-content-between mb-3">
          <div className="flex-grow-1">
            <h5 className="text-dark">Upload Documents</h5>
          </div>
          {detailData?.Details?.application_id && (
            <div className="flex-shrink-0">
              <h6 className="text-dark fw-bold alert alert-primary p-2 m-0">
                {detailData?.Details?.application_id}
              </h6>
            </div>
          )}
        </div>
        <div>
          <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
            <div className="">
              <div className="row g-3">
                <div className="col-lg-4 col-md-6">
                  <div className="border p-3 rounded-3">
                    <label className="form-label">Passport Photo</label>
                    <div className="bg-light p-3 rounded mb-1 text-center">
                      {selectedFiles?.passport.length > 0 ? (
                        <div className="">
                          <img
                            src={window.URL.createObjectURL(
                              selectedFiles?.passport[0]
                            )}
                            className="img-fluid"
                            alt="document"
                          />
                        </div>
                      ) : detailData?.passport ? (
                        <img
                          src={`${detailData?.uploads_document_url}${detailData?.passport}`}
                          className="img-fluid"
                          alt="document"
                        />
                      ) : (
                        <img
                          src="https://via.placeholder.com/200x200"
                          className="img-fluid"
                          alt="document"
                        />
                      )}
                    </div>
                    <div className="d-flex gap-2">
                      <div className="flex-grow-1">
                        <input
                          className="form-control"
                          type="file"
                          accept=".png, .jpg, .jpeg"
                          {...register("passport", {
                            onChange: (e) =>
                              uploadInputFiles(e, [".png", ".jpg", ".jpeg"]),
                          })}
                        />
                      </div>
                      {detailData?.passport && (
                        <div className="flex-shrink-0">
                          <a
                            download
                            className="btn btn-primary"
                            href={`${detailData?.uploads_document_url}${detailData?.passport}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <FaDownload />
                          </a>
                        </div>
                      )}
                    </div>

                    <div className="small alert-warning m-0 alert p-2 mt-1">
                      [Allowed Format : jpg, jpeg, png]
                      <br />
                      [Max File Size : 20mb]
                    </div>
                    {errors.passport && (
                      <div className="error_line">Passport Photo is required</div>
                    )}
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="border p-3 rounded-3">
                    <label className="form-label">Aadhar Document</label>
                    <div className="bg-light p-3 rounded mb-1 text-center">
                      {selectedFiles?.aadhar.length > 0 ? (
                        <div className="">
                          <img
                            src={window.URL.createObjectURL(
                              selectedFiles?.aadhar[0]
                            )}
                            className="img-fluid"
                            alt="aadhar"
                          />
                        </div>
                      ) : detailData?.aadhar ? (
                        <img
                          src={`${detailData?.uploads_document_url}${detailData?.aadhar}`}
                          className="img-fluid"
                          alt="aadhar"
                        />
                      ) : (
                        <img
                          src="https://via.placeholder.com/200x200"
                          className="img-fluid"
                          alt="aadhar"
                        />
                      )}
                    </div>

                    <div className="d-flex gap-2">
                      <div className="flex-grow-1">
                        <input
                          className="form-control"
                          type="file"
                          {...register("aadhar", {
                            onChange: (e) =>
                              uploadInputFiles(e, [".png", ".jpg", ".jpeg"]),
                          })}
                          accept=".png, .jpg, .jpeg"
                        />
                      </div>

                      {detailData?.aadhar && (
                        <div className="flex-shrink-0">
                          <a
                            rel="noreferrer"
                            download
                            className="btn btn-primary"
                            href={`${detailData?.uploads_document_url}${detailData?.aadhar}`}
                            target="_blank"
                          >
                            <FaDownload />
                          </a>
                        </div>
                      )}
                    </div>
                    <div className="small alert-warning m-0 alert p-2 mt-1">
                      [Allowed Format : jpg, jpeg, png]
                      <br />
                      [Max File Size : 20mb]
                    </div>
                    {errors.aadhar && (
                      <div className="error_line">
                        Aadhar Document is required
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="border p-3 rounded-3">
                    <label className="form-label">
                      Upload Certificate (Medical, Death & Succession)
                    </label>
                    <div className="bg-light p-3 rounded mb-1 text-center">
                      {selectedFiles?.certificate.length > 0 ? (
                        <div className="">
                          <embed
                            src={window.URL.createObjectURL(
                              selectedFiles?.certificate[0]
                            )}
                            style={{
                              width: "100%",
                              border: "none",
                              height: "200px",
                            }}
                          />
                        </div>
                      ) : detailData?.certificate ? (
                        <embed
                          src={`${detailData?.uploads_document_url}${detailData?.certificate}`}
                          style={{
                            width: "100%",
                            border: "none",
                            height: "200px",
                          }}
                        />
                      ) : (
                        <img
                          src="https://via.placeholder.com/200x200"
                          className="img-fluid"
                          alt="certificate"
                        />
                      )}
                    </div>

                    <div className="d-flex gap-2">
                      <div className="flex-grow-1">
                        <input
                          className="form-control"
                          type="file"
                          {...register("certificate", {
                            onChange: (e) => uploadInputFiles(e, [".pdf"]),
                          })}
                          accept=".pdf"
                        />
                      </div>
                      {detailData?.certificate && (
                        <div className="flex-shrink-0">
                          <a
                            download
                            rel="noreferrer"
                            className="btn btn-primary"
                            href={`${detailData?.uploads_document_url}${detailData?.certificate}`}
                            target="_blank"
                          >
                            <FaDownload />
                          </a>
                        </div>
                      )}
                    </div>
                    <div className="small alert-warning m-0 alert p-2 mt-1">
                      [Allowed Format : pdf]
                      <br />
                      [Max File Size : 20mb]
                    </div>
                    {errors.certificate && (
                      <div className="error_line">Certificate is required</div>
                    )}
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="border p-3 rounded-3">
                    <label className="form-label">Signature</label>
                    <div className="bg-light p-3 rounded mb-1 text-center">
                      {selectedFiles?.signature.length > 0 ? (
                        <div className="">
                          <img
                            src={window.URL.createObjectURL(
                              selectedFiles?.signature[0]
                            )}
                            alt="Signature"
                            className="img-fluid"
                          />
                        </div>
                      ) : detailData?.signature ? (
                        <img
                          src={`${detailData?.uploads_document_url}${detailData?.signature}`}
                          className="img-fluid"
                          alt="Signature"
                        />
                      ) : (
                        <img
                          src="https://via.placeholder.com/250x100"
                          className="img-fluid"
                          alt="Signature"
                        />
                      )}
                    </div>

                    <div className="d-flex gap-2">
                      <div className="flex-grow-1">
                        <input
                          className="form-control"
                          type="file"
                          {...register("signature", {
                            onChange: (e) =>
                              uploadInputFiles(e, [".png", ".jpg", ".jpeg"]),
                          })}
                          accept=".png, .jpg, .jpeg"
                        />
                      </div>
                      {detailData?.signature && (
                        <div className="flex-shrink-0">
                          <a
                            download
                            rel="noreferrer"
                            className="btn btn-primary"
                            href={`${detailData?.uploads_document_url}${detailData?.signature}`}
                            target="_blank"
                          >
                            <FaDownload />
                          </a>
                        </div>
                      )}
                    </div>
                    <div className="small alert-warning m-0 alert p-2 mt-1">
                      [Allowed Format : jpg, jpeg, png]
                      <br />
                      [Max File Size : 20mb]
                    </div>
                    {errors.signature && (
                      <div className="error_line">Signature is required</div>
                    )}
                  </div>
                </div>
                <div className="col-lg-8 col-md-6">
                  <div className="border p-3 rounded-3">
                    <label className="form-label">
                      Upload damage images{" "}
                      <span className="text-danger fw-bold">*</span>
                    </label>
                    <div className="bg-light p-3 rounded mb-1 text-center">
                      {selectedFiles["damages[]"].length > 0 ? (
                        <div className="d-flex flex-wrap gap-3">
                          {selectedFiles["damages[]"].map((a, i) => {
                            return (
                              <div className="damage_images_box" key={i}>
                                <img
                                  src={window.URL.createObjectURL(a)}
                                  alt="Damage"
                                />
                              </div>
                            );
                          })}
                        </div>
                      ) : detailData?.damages?.length > 0 ? (
                        <div className="d-flex flex-wrap gap-3">
                          {detailData?.damages.map((a, i) => {
                            return (
                              <div className="damage_images_box" key={i}>
                                <img
                                  src={`${detailData?.uploads_document_url}${a}`}
                                  alt="Damage"
                                />
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        <img
                          src="https://via.placeholder.com/300x100?text=Damage%20images"
                          className="img-fluid"
                          alt="Damage"
                        />
                      )}
                    </div>
                    <input
                      className="form-control"
                      type="file"
                      accept=".png, .jpg, .jpeg"
                      multiple
                      {...register("damages[]", {
                        required:
                          detailData?.damages?.length > 0 ? false : true,
                        onChange: (e) =>
                          uploadInputFiles(e, [".png", ".jpg", ".jpeg"]),
                      })}
                    />
                    <div className="small alert-warning m-0 alert p-2 mt-1">
                      [Allowed Format : jpg, jpeg, png] [Maximum Number Of Image
                      : 5]
                      <br />
                      [Max File Size : 20mb]
                    </div>
                    {errors.damages && (
                      <div className="error_line">
                        Damage images is required
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-12">
                  <div>
                    <input
                      type="submit"
                      value="Submit"
                      className="btn btn-primary px-5"
                      disabled={isLoading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default SdrfDocuments;
