import React, { useEffect, useState } from "react";
import Api from "../../config/Api";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { Modal } from "react-bootstrap";
import { BsPlusLg, BsFillPencilFill, BsFillTrashFill } from "react-icons/bs";
import GifImg from "../../assets/images/loader.gif";
import TooltipWidget from "../Components/Widget/TooltipWidget";

const NatureOfDisaster = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const [disasterList, setDisasterList] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [disasterUpdate, setDisasterUpdate] = useState({
    id: "",
    isUpdating: false,
  });
  const [searchItem, setSearchItem] = useState("");

  const disasterModalClose = () => {
    reset({
      title: "",
      multiselect: "",
      status: "",
    });
    setModalShow(false);
    setDisasterUpdate({ id: "", isUpdating: false });
  };

  const disasterModalShow = () => setModalShow(true);

  //getDisaster
  const getDisaster = async () => {
    try {
      const res = await Api("nature_of_disaster_get");
      if (res.data.status === 1) {
        setDisasterList(res.data.NatureOfDisaster.reverse());
        setIsDataLoaded(true);
      } else {
        toast.error(res?.data?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    } catch (error) {
      toast.error(error.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
  //getDisaster

  //editDisaster
  const editDisaster = async (disasterObj) => {
    setDisasterUpdate({
      id: disasterObj.id,
      isUpdating: true,
    });
    disasterModalShow();
    reset({
      title: disasterObj.title,
      status: disasterObj.status,
    });
  };
  //editDisaster

  //removeDisaster
  const removeDisaster = async (disasterObj) => {
    try {
      // console.log(disasterObj);
      if (disasterObj) {
        if (window.confirm("Are you sure you want to delete?") === true) {
          const body = {
            id: disasterObj.id,
          };
          const res = await Api("nature_of_disaster_delete", "POST", body);
          setIsDataLoaded(false);
          // console.log(res);
          if (res.data.status === 1) {
            toast.success("Nature of Disaster Deleted Successfully!", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });

            getDisaster();
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        }
      }
    } catch (error) {
      toast.error(error.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
  //removeDisaster

  useEffect(() => {
    getDisaster();
  }, []);

  const onSubmit = async (d) => {
    try {
      setIsLoading(true);
      // console.log(d);
      if (disasterUpdate.isUpdating) {
        let body = {
          title: d.title,
          status: d.status,
        };
        // console.log(body);
        const res = await Api(
          `nature_of_disaster_update/${disasterUpdate.id}`,
          "POST",
          body
        );
        // console.log(res);
        if (res.data.status === 1) {
          toast.success("Nature Of Disaster Updated Successfully!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setDisasterUpdate({
            id: "",
            isUpdating: false,
          });
          reset();
          disasterModalClose();
          setIsDataLoaded(false);
          getDisaster();
          setIsLoading(false);
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setIsLoading(false);
        }
      } else {
        let body = {
          title: d.title,
          status: d.status,
        };
        const res = await Api("nature_of_disaster_add", "POST", body);
        // console.log(res);
        if (res.data.status === 1) {
          toast.success("Nature Of Disaster Added Successfully!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          reset();
          disasterModalClose();
          setIsDataLoaded(false);
          getDisaster();
          setIsLoading(false);
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setIsLoading(false);
        }
      }
    } catch (error) {
      toast.error(error.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  const handleInputChange = (e) => {
    setSearchItem(e.target.value);
  };

  const filteredItems = !searchItem
    ? disasterList
    : disasterList.filter((disaster) =>
        disaster.title.toLowerCase().includes(searchItem.toLowerCase())
      );

  return (
    <>
      <div className="bg-white p-3 rounded p-3 shadow">
        <div className="d-sm-flex flex-wrap justify-content-between mb-3">
          <div className="flex-grow-1">
            <h5 className="text-dark">Nature Of Disaster</h5>
          </div>
          <div className="flex-shrink-0">
            <div className="d-flex flex-wrap align-items-center gap-2">
              <div>
                <input
                  type="text"
                  value={searchItem}
                  onChange={handleInputChange}
                  placeholder="Type to search"
                  className="form-control"
                />
              </div>

              <button
                type="button"
                className="btn btn-primary"
                onClick={disasterModalShow}
              >
                <BsPlusLg className="me-2" />
                Add Nature Of Disaster
              </button>
            </div>
          </div>
        </div>
        {isDataLoaded ? (
          filteredItems.length > 0 ? (
            <div className="table-responsive">
              <table className="table table-bordered m-0">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Nature Of Disaster</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredItems?.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{item?.title}</td>
                        <td>
                          {item?.status === "Active" ? (
                            <span className="badge bg-success">Active</span>
                          ) : item.status === "Deactive" ? (
                            <span className="badge bg-danger">Deactive</span>
                          ) : (
                            ""
                          )}
                        </td>
                        <td>
                          <div className="d-flex gap-2 flex-wrap">
                            <TooltipWidget title="Edit">
                              <button
                                className="btn btn-primary btn-sm"
                                type="button"
                                onClick={() => editDisaster(item)}
                              >
                                <BsFillPencilFill />
                              </button>
                            </TooltipWidget>
                            <TooltipWidget title="Delete">
                              <button
                                className="btn btn-danger btn-sm"
                                type="button"
                                onClick={() => removeDisaster(item)}
                              >
                                <BsFillTrashFill />
                              </button>
                            </TooltipWidget>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="alert alert-danger m-0">
              <p>No Nature Of Disaster Added</p>
            </div>
          )
        ) : (
          <div className="loaderbox">
            <img src={GifImg} alt="Loader" />
            <p>Loading...</p>
          </div>
        )}
      </div>

      <Modal
        show={modalShow}
        onHide={disasterModalClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-dark">
            {disasterUpdate.isUpdating ? "Edit" : "Add"} Nature Of Disaster
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row g-3">
                <div className="col-sm-12">
                  <label className="form-label">
                    Nature Of Disaster Title{" "}
                    <span className="fw-bold text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    {...register("title", { required: true })}
                    disabled={isLoading}
                  />
                  {errors.title && (
                    <div className="error_line">Title is required </div>
                  )}
                </div>
                <div className="col-sm-12">
                  <label className="form-label">
                    Status <span className="fw-bold text-danger">*</span>
                  </label>
                  <select
                    className="form-select"
                    {...register("status", { required: true })}
                    disabled={isLoading}
                  >
                    <option value="">Select Status</option>
                    <option value="Active">Active</option>
                    <option value="Deactive">Deactive</option>
                  </select>
                  {errors.status && (
                    <div className="error_line">Status is required</div>
                  )}
                </div>

                <div className="col-12">
                  <div>
                    <input
                      type="submit"
                      value={disasterUpdate.isUpdating ? "Update" : "Submit"}
                      className="btn btn-primary"
                      disabled={isLoading}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NatureOfDisaster;
