import React, { useState } from "react";
import {
  GOOGLE_MAPS_API_KEY,
  LIBRARIES,
} from "../../../../config/react-config";
import {
  GoogleMap,
  LoadScript,
  Marker,
  StandaloneSearchBox,
} from "@react-google-maps/api";
import Geocode from "react-geocode";
import { toast } from "react-toastify";

const containerStyle = {
  width: "100%",
  height: "400px",
};

// set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
Geocode.setApiKey(GOOGLE_MAPS_API_KEY);

// set response language. Defaults to english.
Geocode.setLanguage("en");

// set response region. Its optional.
// A Geocoding request with region=es (Spain) will return the Spanish city.
Geocode.setRegion("es");

// set location_type filter . Its optional.
// google geocoder returns more that one address for given lat/lng.
// In some case we need one address as response for which google itself provides a location_type filter.
// So we can easily parse the result for fetching address components
// ROOFTOP, RANGE_INTERPOLATED, GEOMETRIC_CENTER, APPROXIMATE are the accepted values.
// And according to the below google docs in description, ROOFTOP param returns the most accurate result.
Geocode.setLocationType("ROOFTOP");

// Enable or disable logs. Its optional.
Geocode.enableDebug();

const MapContainer = ({ reset, mapModalClose, google }) => {
  const [searchBox, setSearchBox] = useState(null);
  const [mapData, setMapData] = useState({
    address: "",
    showingInfoWindow: false,
    activeMarker: {},
    selectedPlace: {},
    mapCenter: {
      lat: 26.1584,
      lng: 94.5624,
    },
  });

  const onPlacesChanged = () => {
    try {
      let add = searchBox.getPlaces()[0].formatted_address;
      let lat = searchBox.getPlaces()[0].geometry.location.lat();
      let lng = searchBox.getPlaces()[0].geometry.location.lng();

      // console.log(add);
      reset((prev) => ({
        ...prev,
        location_disaster: add,
        latitude: lat,
        longitude: lng,
      }));

      setMapData((prev) => ({
        ...prev,
        address: add,
        mapCenter: {
          lat: lat,
          lng: lng,
        },
      }));
    } catch (error) {
      toast.error(error.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  const onLoad = (ref) => {
    setSearchBox(ref);
  };

  const onMarkerDragEnd = (e) => {
    try {
      let lat = e.latLng.lat();
      let lng = e.latLng.lng();

      Geocode.fromLatLng(lat, lng).then(
        (response) => {
          const address = response.results[0].formatted_address;
          // console.log("response>>>",response);
          // console.log("address>>>",address);

          reset((prev) => ({
            ...prev,
            location_disaster: address,
            latitude: lat,
            longitude: lng,
          }));

          setMapData((prev) => ({
            ...prev,
            mapCenter: {
              lat: lat,
              lng: lng,
            },
          }));
        },
        (error) => {
          // console.error(error);
          toast.error('Place not found', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      );
    } catch (error) {
      toast.error(error.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  const submitAddress = () => {
    // reset({ latitude: lat, longitude: lng });
    mapModalClose();
  };

  return (
    <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY} libraries={LIBRARIES}>
      <div className="row g-2">
        <div className="col-12">
          <div>
            <StandaloneSearchBox
              onLoad={onLoad}
              onPlacesChanged={onPlacesChanged}
            >
              <input
                type="text"
                placeholder="Search Places.."
                className="form-control"
              />
            </StandaloneSearchBox>
          </div>
        </div>
        <div className="col-12">
          <div className="">
            <GoogleMap
              id="searchbox-example"
              mapContainerStyle={containerStyle}
              google={google}
              initialCenter={{
                lat: mapData.mapCenter.lat,
                lng: mapData.mapCenter.lng,
              }}
              center={{
                lat: mapData.mapCenter.lat,
                lng: mapData.mapCenter.lng,
              }}
              zoom={7}
            >
              <Marker
                position={{
                  lat: mapData.mapCenter.lat,
                  lng: mapData.mapCenter.lng,
                }}
                draggable={true}
                onDragEnd={(e) => onMarkerDragEnd(e)}
              />
            </GoogleMap>
          </div>
        </div>
        <div className="col-12">
          <div className="text-center">
            <button
              type="button"
              onClick={submitAddress}
              className="btn btn-primary btn-lg"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </LoadScript>
  );
};

export default MapContainer;
