import React from "react";
import { Outlet } from "react-router-dom";
// import Sidebar from "./Sidebar";
import Header from "./Header";
import Footer from "./Footer";

const HomeLayout = () => {
  // const [isBarOpen, setIsBarOpen] = useState(false);
  // const toggleBarBtn = () => {
  //   setIsBarOpen(!isBarOpen);
  // };
  return (
    <>
      <section className="pagesec">
        <div className="">
          <div className="sidecontent">
            <Header islogo={true} />
            <div className="contentbox p-3">
              <Outlet />
            </div>
          </div>
        </div>
        <Footer />
      </section>
    </>
  );
};

export default HomeLayout;
