import React from "react";
import { Doughnut } from "react-chartjs-2";

const Graph3 = ({ data }) => {
  const data1 = {
    labels: ["Yes", "No"],
    datasets: [
      {
        label: "",
        data: [data?.yes, data?.no],
        borderWidth: 1,
      },
    ],
  };

  const option = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      datalabels: { display: true, color: "#fff" },
      legend: {
        position: "bottom",
      },
      colors: {
        forceOverride: true,
      },
    },
  };

  return (
    <div className="bg-white p-3 rounded-3 border">
      <div className="mb-3">
        <h6 className="text-dark fw-bold">Deceased wise SDRF</h6>
      </div>
      <div className="chartbox mt-3">
        <Doughnut options={option} data={data1} />
      </div>
    </div>
  );
};

export default Graph3;
