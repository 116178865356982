import React from "react";
import { Doughnut } from "react-chartjs-2";

const Graph5 = ({ data }) => {
  const object1 = data;
  var pieLabels1 = [];
  var pieCounts1 = [];
  // var pie2colors = [];
  if (object1 !== undefined) {
    for (const [key, value] of Object.entries(object1)) {
      // console.log(`${key}: ${value}`);
      pieLabels1.push(key);
      pieCounts1.push(value);
      // pie2colors.push(randomColor());
    }
  }

  const data1 = {
    labels: pieLabels1,
    datasets: [
      {
        label: "",
        data: pieCounts1,
        // backgroundColor: pie1colors,
        borderWidth: 1,
      },
    ],
  };

  const option = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      datalabels: { display: true, color: "#fff" },
      legend: {
        position: "bottom",
      },
      colors: {
        forceOverride: true,
      },
    },
  };

  return (
    <div className="bg-white p-3 rounded-3 border">
      <div className="mb-3">
        <h6 className="text-dark fw-bold">Nature of disaster wise SDRF</h6>
      </div>
      <div className="chartbox mt-3">
        <Doughnut options={option} data={data1} />
      </div>
    </div>
  );
};

export default Graph5;
