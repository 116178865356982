import React, { useEffect, useState } from "react";
import Api from "../../config/Api";
import { useForm, Controller } from "react-hook-form";
import { Modal } from "react-bootstrap";
import GifImg from "../../assets/images/loader.gif";
import Select from "react-select";
import { toast } from "react-toastify";
import { BsPlusLg, BsFillPencilFill, BsFillTrashFill } from "react-icons/bs";
import TooltipWidget from "../Components/Widget/TooltipWidget";

const ManageRole = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm();

  const [roleList, setRoleList] = useState([]);
  const [permissionList, setPermissionList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isModalShow, setIsModalShow] = useState(false);
  const [roleUpdate, setRoleUpdate] = useState({
    id: "",
    isUpdating: false,
  });

  const modalClose = () => {
    setIsModalShow(false);
    reset({
      role: "",
      // access_level: "",
      status: "",
    });
    setRoleUpdate({ id: "", isUpdating: false });
  };
  const modalShow = () => setIsModalShow(true);

  //get roles
  const getRoles = async () => {
    try {
      setIsLoading(true);
      const res = await Api("get-role");
      // console.log(res);
      if (res?.data?.status === 1) {
        setRoleList(res?.data?.roleDetail.reverse());
        setIsLoading(false);
        setIsDataLoaded(true);
        return true;
      } else {
        toast.error(res?.data?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsLoading(false);
        return true;
      }
    } catch (error) {
      toast.error(error.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
  //get roles

  const optionType = (d) => {
    return d.map((a) => {
      return {
        label: a.name,
        value: a.name,
      };
    });
  };

  //get permission
  const getPermission = async () => {
    try {
      setIsLoading(true);
      const res = await Api("permission_list");
      // console.log(res);
      if (res?.data?.status === 1) {
        // setPermissionList(optionType(res?.data?.data));
        setPermissionList(optionType(res?.data?.data));
        setIsLoading(false);
      } else {
        toast.error(res?.data?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsLoading(false);
      }
    } catch (error) {
      toast.error(error.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
  //get permission

  const style = {
    control: (base, state) => ({
      ...base,
      border: state.isFocused ? "1px solid #224b8b" : "1px solid #ddd",
      boxShadow: "none",
      backgroundColor: state.isFocused ? "" : "#fbfbfb",
      "&:hover": {
        borderColor: "#224b8b",
      },
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected ? "#224b8b" : "",
      "&:hover": {
        color: "#fff",
        backgroundColor: "#224b8b",
      },
    }),
  };

  //Update Role
  const editRole = async (roleObj) => {
    // console.log(roleObj);
    modalShow();
    setRoleUpdate({ id: roleObj.id, isUpdating: true });
    const temparr = await roleObj?.permission?.split(", ");
    const tempobj = temparr?.map((a) => {
      return {
        label: a,
        value: a,
      };
    });
    // console.log(tempobj);
    reset({
      role: roleObj.role_name,
      permission: tempobj,
      status: roleObj.status,
    });
  };
  //Update Role

  //Delete Role
  const removeRole = async (roleObj) => {
    try {
      if (roleObj?.id) {
        if (window.confirm("Are you sure you want to delete?") === true) {
          setIsLoading(true);
          setIsDataLoaded(false);
          const body = {
            id: roleObj?.id,
          };
          const res = await Api("delete-role", "POST", body);
          // console.log(res);
          if (res.data.status === 1) {
            let resRole = await getRoles();
            if (resRole) {
              toast.success("Role Deleted Successfully!", {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
              setIsLoading(false);
            }
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            setIsLoading(false);
          }
        }
      }
    } catch (error) {
      toast.error(error.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
  //Delete Role

  const getBadges = (item) => {
    let arr = item.split(", ");
    return arr.map((a, i) => {
      return (
        <span key={i} className="alert alert-primary py-1 px-2 m-0">
          {a}
        </span>
      );
    });
  };

  useEffect(() => {
    getRoles();
    getPermission();
    // eslint-disable-next-line
  }, []);

  const DropDown = (props) => {
    const options = props.multi
      ? [{ label: "Select All", value: "all" }, ...props.options]
      : props.options;

    return (
      <div className={`react-select-wrapper ${props.multi ? "multi" : ""}`}>
        <Controller
          control={control}
          name="permission"
          rules={{ required: true }}
          render={({ field }) => (
            <Select
              {...field}
              styles={style}
              placeholder={"Select Permission"}
              options={options}
              // closeMenuOnSelect={false}
              isDisabled={isLoading}
              isMulti
              value={field.value ? field.value : null}
              onChange={(selected) => {
                // field.onChange(selected);
                props.multi &&
                selected.length &&
                selected.find((option) => option.value === "all")
                  ? field.onChange(options.slice(1))
                  : !props.multi
                  ? field.onChange((selected && selected.value) || null)
                  : field.onChange(selected);
              }}
            />
          )}
        />
      </div>
    );
  };

  const onSubmit = async (d) => {
    // console.log(d);

    try {
      setIsLoading(true);
      // console.log(d);
      if (roleUpdate.isUpdating) {
        const body = {
          role_name: d.role,
          permission: d.permission.map((item) => item.value),
          status: d.status,
        };
        // console.log(body);
        const res = await Api(`update-role/${roleUpdate.id}`, "POST", body);
        // console.log(res);
        if (res?.data?.status === 1) {
          toast.success("Role Updated Successfully!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          reset();
          setIsLoading(false);
          modalClose();
          setIsDataLoaded(false);
          setRoleUpdate({ id: "", isUpdating: false });
          getRoles();
        } else {
          toast.error(res?.data?.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setIsLoading(false);
        }
      } else {
        const body = {
          role_name: d.role,
          permission: d.permission.map((item) => item.value),
          status: d.status,
        };
        // console.log(body);
        const res = await Api("add-role", "POST", body);
        // console.log(res);
        if (res?.data?.status === 1) {
          toast.success("Role Added Successfully!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          reset();
          setIsLoading(false);
          modalClose();
          setIsDataLoaded(false);
          getRoles();
        } else {
          toast.error(res?.data?.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setIsLoading(false);
        }
      }
    } catch (error) {
      toast.error(error.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  return (
    <>
      <div className="bg-white shadow-sm p-3">
        <div className="d-flex">
          <div className="flex-grow-1">
            <h5 className="text-dark">Manage Role</h5>
          </div>
          <div className="flex-shrink-0">
            <button
              type="button"
              className="btn btn-primary btn-sm"
              onClick={modalShow}
              disabled={isLoading}
            >
              <BsPlusLg className="me-2" />
              Add New Role
            </button>
          </div>
        </div>
        <div className="mt-3">
          {isDataLoaded ? (
            roleList.length > 0 ? (
              <div className="table-responsive mt-3">
                <table className="table table-bordered m-0">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Role Name</th>
                      <th style={{ width: "50%" }}>Permission</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {roleList.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>{item.role_name}</td>
                          <td>
                            <div className="d-flex flex-wrap gap-1 small">
                              {getBadges(item.permission)}
                            </div>
                          </td>
                          <td>
                            {item?.status === "Active" ? (
                              <span className="badge bg-success">Active</span>
                            ) : item.status === "Deactive" ? (
                              <span className="badge bg-danger">Deactive</span>
                            ) : (
                              ""
                            )}
                          </td>
                          <td>
                            <div className="d-flex gap-2 flex-wrap">
                              <TooltipWidget title="Edit">
                                <button
                                  onClick={() => editRole(item)}
                                  className="btn btn-primary btn-sm"
                                  type="button"
                                >
                                  <BsFillPencilFill />
                                </button>
                              </TooltipWidget>
                              <TooltipWidget title="Delete">
                                <button
                                  onClick={() => removeRole(item)}
                                  className="btn btn-danger btn-sm"
                                  type="button"
                                >
                                  <BsFillTrashFill />
                                </button>
                              </TooltipWidget>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="alert alert-danger m-0">
                <p>No Roles Added</p>
              </div>
            )
          ) : (
            <div className="loaderbox">
              <img src={GifImg} alt="Loader" />
              <p>Loading...</p>
            </div>
          )}
        </div>
      </div>
      <Modal
        show={isModalShow}
        onHide={modalClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-dark">
            {roleUpdate.isUpdating ? "Edit Role" : "Add Role"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row g-3">
                <div className="col-12">
                  <div>
                    <label className="form-label">
                      Role Name <span className="text-danger fw-bold">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      disabled={isLoading}
                      {...register("role", { required: true })}
                    />
                    {errors.role && (
                      <div className="error_line">Role is required</div>
                    )}
                  </div>
                </div>
                <div className="col-12">
                  <div className="">
                    <label className="form-label">
                      Permission <span className="text-danger fw-bold">*</span>
                    </label>
                    <DropDown options={permissionList} multi={true} />

                    {errors.permission && (
                      <div className="error_line">Permission is required</div>
                    )}
                  </div>
                </div>
                <div className="col-12">
                  <div>
                    <label className="form-label">
                      Select Status{" "}
                      <span className="text-danger fw-bold">*</span>
                    </label>
                    <select
                      disabled={isLoading}
                      className="form-select"
                      {...register("status", { required: true })}
                    >
                      <option value="">Select Status</option>
                      <option value="Active">Active</option>
                      <option value="Deactive">Deactive</option>
                    </select>
                    {errors.status && (
                      <div className="error_line">Status is required</div>
                    )}
                  </div>
                </div>
                <div className="col-12">
                  <div className="">
                    <button
                      disabled={isLoading}
                      className="btn btn-primary"
                      type="submit"
                    >
                      {roleUpdate.isUpdating
                        ? isLoading
                          ? "Updating..."
                          : "Update"
                        : isLoading
                        ? "Submitting..."
                        : "Submit"}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ManageRole;
