import React, { useEffect, useState } from "react";
import Api from "../../config/Api";
import { toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import { Modal } from "react-bootstrap";
import { BsPlusLg, BsFillPencilFill, BsFillTrashFill } from "react-icons/bs";
import GifImg from "../../assets/images/loader.gif";
import Select from "react-select";
import Pagination from "react-bootstrap/Pagination";
import TooltipWidget from "../Components/Widget/TooltipWidget";

const SubCategory = () => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
    watch,
  } = useForm();

  const [itemDamaged, setItemDamaged] = useState([]);
  const [category, setCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [searchItem, setSearchItem] = useState("");
  const [damageUpdate, setDamageUpdate] = useState({
    id: "",
    isUpdating: false,
  });
  const style = {
    control: (base, state) => ({
      ...base,
      border: state.isFocused ? "1px solid #224b8b" : "1px solid #ddd",
      boxShadow: "none",
      backgroundColor: state.isFocused ? "" : "#fbfbfb",
      "&:hover": {
        borderColor: "#224b8b",
      },
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected ? "#224b8b" : "",
      "&:hover": {
        color: "#fff",
        backgroundColor: "#224b8b",
      },
    }),
  };

  const damageModalClose = () => {
    reset({
      first_damage_classification_id: "",
      second_damage_classification_id: "",
      name: "",
      min: "",
      max: "",
      measurement_unit: "",
      amount_per_unit: "",
      status: "",
    });
    setModalShow(false);
    setDamageUpdate({ id: "", isUpdating: false });
  };

  const damageModalShow = () => setModalShow(true);

  const categoryListOption = (data) => {
    return data
      ?.filter((d) => d.status === "Active")
      .map((item) => {
        return {
          label: item.title,
          value: item.id,
        };
      });
  };
  const damageListOption = (data) => {
    return data
      ?.filter((d) => d.status === "Active")
      .map((item) => {
        return {
          label: item.damage_name,
          value: item.id,
        };
      });
  };

  //getItemDamages
  const getItemDamages = async () => {
    try {
      // setIsLoading(true);
      const res = await Api("first_damage_classification_get");
      // console.log(res);
      if (res.data.status === "1") {
        setItemDamaged(categoryListOption(res.data.DamageClassificationData));
        setIsLoading(false);
      } else {
        toast.error(res?.data?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsLoading(false);
      }
    } catch (error) {
      toast.error(error.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
  //getItemDamages

  //get damages
  const getCategory = async (id) => {
    try {
      setIsFetching(true);
      setIsLoading(true);
      const res = await Api(`second_damage_classification_get/${id}`);
      // console.log(res);
      if (res.data.status === 1) {
        setCategory(damageListOption(res.data.DamageClassificationData));
        setIsLoading(false);
        setIsFetching(false);
        return true;
      } else {
        toast.error(res?.data?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsFetching(false);
        setIsLoading(false);
      }
    } catch (error) {
      toast.error(error.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
  //get damages

  //getDamagesOption
  const getSubCategory = async (pageCount) => {
    try {
      const res = await Api(
        `third_damage_classification_get?page=${pageCount}&search=${searchItem}`
      );
      // console.log(res);
      if (res.data.status === 1) {
        setSubCategory(res.data.data);
        setIsDataLoaded(true);
      } else {
        toast.error(res?.data?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    } catch (error) {
      toast.error("Something went wrong!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
  //getDamagesOption

  //Update Damage
  const editDamage = async (damageObj) => {
    try {
      // console.log(damageObj);
      setDamageUpdate({
        id: damageObj.id,
        isUpdating: true,
      });
      damageModalShow();

      const res = await getCategory(damageObj?.first_damage?.id);
      if (res) {
        reset({
          first_damage_classification_id: {
            label: damageObj?.first_damage?.title,
            value: damageObj?.first_damage?.id,
          },
          second_damage_classification_id: {
            label: damageObj?.second_damage?.damage_name,
            value: damageObj?.second_damage?.id,
          },
          name: damageObj?.name,
          min: damageObj?.min,
          max: damageObj?.max,
          // measurement_unit: damageObj?.unit_of_quantity,
          amount_per_unit: damageObj?.amount_per_unit,
          status: damageObj?.status,
        });
      }
    } catch (error) {
      toast.error(error.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
  //Update Damage

  //Delete Damage
  const removeDamage = async (damageObj) => {
    try {
      if (damageObj) {
        if (window.confirm("Are you sure you want to delete?") === true) {
          setIsFetching(true);
          const body = {
            id: damageObj.id,
          };
          const res = await Api(
            "third_damage_classification_delete",
            "POST",
            body
          );
          // console.log(res);
          setIsFetching(false);
          setIsDataLoaded(false);
          if (res.data.status === 1) {
            toast.success("Sub Category Deleted Successfully!", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            getSubCategory(1);
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        }
      }
    } catch (error) {
      toast.error(error.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
  //Delete Damage

  const inputChange = (e) => {
    getCategory(e.value);
  };

  //pagination count
  // const IndexCount = ({ index }) => {
  //   let cp = subCategory.current_page;
  //   let pp = subCategory.per_page;
  //   let count;
  //   if (cp > 1) {
  //     count = (cp - 1) * pp + index;
  //   } else {
  //     count = index;
  //   }
  //   return count;
  // };
  //pagination count

  //pagination
  const PaginationRow = () => {
    return (
      <div className="mt-4 d-flex justify-content-md-center overflow-auto">
        <Pagination>
          {subCategory?.links?.map((item, i) => {
            if (i === 0) {
              return (
                <Pagination.Prev
                  key={i}
                  onClick={() => getSubCategory(subCategory?.current_page - 1)}
                  disabled={subCategory?.current_page === 1 ? true : false}
                />
              );
            } else if (item.label === "...") {
              return <Pagination.Ellipsis key={i} />;
            } else if (i + 1 === subCategory?.links.length) {
              return (
                <Pagination.Next
                  key={i}
                  onClick={() => getSubCategory(subCategory?.current_page + 1)}
                  disabled={
                    subCategory?.current_page === subCategory?.last_page
                      ? true
                      : false
                  }
                />
              );
            } else if (i !== subCategory?.links.length) {
              return (
                <Pagination.Item
                  key={i}
                  active={Number(item.label) === subCategory?.current_page}
                  onClick={() => getSubCategory(item.label)}
                >
                  {item?.label}
                </Pagination.Item>
              );
            } else {
              return false;
            }
          })}
        </Pagination>
      </div>
    );
  };
  //pagination
  useEffect(() => {
    getSubCategory(1);
    getItemDamages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (d) => {
    try {
      setIsFetching(true);
      if (damageUpdate.isUpdating) {
        let body = {
          first_damage_classification_id:
            d?.first_damage_classification_id?.value,
          second_damage_classification_id:
            d?.second_damage_classification_id?.value,
          name: d?.name,
          min: d?.min,
          max: d?.max,
          // unit_of_quantity: d?.measurement_unit,
          amount_per_unit: d?.amount_per_unit,
          status: d?.status,
        };
        // console.log(body);
        const res = await Api(
          `third_damage_classification_update/${damageUpdate?.id}`,
          "POST",
          body
        );
        // console.log(res);
        if (res.data.status === 1) {
          toast.success("Sub Category Updated Successfully!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setDamageUpdate({
            id: "",
            isUpdating: false,
          });
          reset();
          damageModalClose();
          setIsDataLoaded(false);
          getSubCategory(1);
          setIsFetching(false);
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setIsFetching(false);
        }
      } else {
        let body = {
          first_damage_classification_id:
            d?.first_damage_classification_id?.value,
          second_damage_classification_id:
            d?.second_damage_classification_id?.value,
          name: d?.name,
          min: d?.min,
          max: d?.max,
          // unit_of_quantity: d?.measurement_unit,
          amount_per_unit: d?.amount_per_unit,
          status: d?.status,
        };
        // console.log(body);
        const res = await Api("third_damage_classification_add", "POST", body);
        // console.log(res);
        if (res.data.status === 1) {
          toast.success("Sub Category Added Successfully!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          reset();
          damageModalClose();
          setIsDataLoaded(false);
          getSubCategory(1);
          setIsFetching(false);
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setIsFetching(false);
        }
      }
    } catch (error) {
      toast.error(error.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  const handleInputChange = (e) => {
    setSearchItem(e.target.value);
  };

//   const filteredItems = !searchItem
//     ? subCategory?.data
//     : subCategory?.data.filter(
//         (subCat) =>
//           subCat?.name.toLowerCase().includes(searchItem.toLowerCase()) ||
//           subCat?.first_damage?.title
//             .toLowerCase()
//             .includes(searchItem.toLowerCase()) ||
//           subCat?.second_damage?.damage_name
//             .toLowerCase()
//             .includes(searchItem.toLowerCase())
//       );

  return (
    <>
      <div className={`loaderoverlay ${isFetching && "show"}`}>
        <div>
          <img src={GifImg} alt="Loader" />
          <p>Loading...</p>
        </div>
      </div>
      <div className="bg-white p-3 rounded p-3 shadow">
        <div className="d-flex flex-wrap gap-2 justify-content-between mb-3">
          <div className="flex-grow-1">
            <h5 className="text-dark">Sub Category</h5>
          </div>
          <div className="">
            <div className="d-flex flex-wrap gap-2 align-items-center">
              <div>
                <input
                  type="text"
                  value={searchItem}
                  onChange={handleInputChange}
                  placeholder="Type to search"
                  className="form-control"
                />
              </div>
              <button type="button" className="btn btn-primary" onClick={getSubCategory}>Search</button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={damageModalShow}
                disabled={isLoading}
              >
                <BsPlusLg className="me-2" />
                Add Sub Category
              </button>
            </div>
          </div>
        </div>
        {isDataLoaded ? (
          subCategory?.data?.length > 0 ? (
            <div className="table-responsive">
              <table className="table table-bordered m-0">
                <thead>
                  <tr>
                    <th>Item Damaged</th>
                    <th>Category</th>
                    <th>Sub Category</th>
                    <th>Min - Max</th>
                    {/* <th>Measurement Unit</th> */}
                    <th>Amount per unit</th>
                    <th>Status</th>
                    <th style={{ width: "100px" }}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {subCategory?.data?.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td>{item?.first_damage?.title}</td>
                        <td>{item?.second_damage?.damage_name}</td>
                        <td>{item?.name}</td>
                        <td>
                          {item?.min} - {item?.max}
                        </td>
                        {/* <td>{item?.unit_of_quantity}</td> */}
                        <td>{item?.amount_per_unit}</td>

                        <td>
                          {item.status === "Active" ? (
                            <span className="badge bg-success">Active</span>
                          ) : item.status === "Deactive" ? (
                            <span className="badge bg-danger">Deactive</span>
                          ) : (
                            ""
                          )}
                        </td>
                        <td>
                          <div className="d-flex gap-2 flex-wrap">
                            <TooltipWidget title="Edit">
                              <button
                                onClick={() => editDamage(item)}
                                className="btn btn-primary btn-sm"
                                type="button"
                              >
                                <BsFillPencilFill />
                              </button>
                            </TooltipWidget>
                            <TooltipWidget title="Delete">
                              <button
                                onClick={() => removeDamage(item)}
                                className="btn btn-danger btn-sm"
                                type="button"
                              >
                                <BsFillTrashFill />
                              </button>
                            </TooltipWidget>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              {subCategory?.last_page > 1 && <PaginationRow />}
            </div>
          ) : (
            <div className="alert alert-danger m-0">
              <p>No Sub Category Added</p>
            </div>
          )
        ) : (
          <div className="loaderbox">
            <img src={GifImg} alt="Loader" />
            <p>Loading...</p>
          </div>
        )}
      </div>

      <Modal
        show={modalShow}
        onHide={damageModalClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-dark">
            {damageUpdate.isUpdating ? "Update" : "Add"} Sub Category
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row g-3">
                <div className="col-sm-12">
                  <label className="form-label">
                    Item Damaged <span className="fw-bold text-danger">*</span>
                  </label>
                  <Controller
                    control={control}
                    rules={{ required: true }}
                    name="first_damage_classification_id"
                    render={({ field }) => (
                      <Select
                        {...field}
                        isDisabled={isLoading}
                        styles={style}
                        placeholder={"Select Item Damaged"}
                        options={itemDamaged}
                        onChange={(e) => {
                          field.onChange(e);
                          inputChange(e);
                        }}
                      />
                    )}
                  />
                  {errors.first_damage_classification_id && (
                    <div className="error_line">Item Damaged is required</div>
                  )}
                </div>
                <div className="col-sm-12">
                  <label className="form-label">
                    Category <span className="fw-bold text-danger">*</span>
                  </label>
                  <Controller
                    control={control}
                    rules={{ required: true }}
                    name="second_damage_classification_id"
                    render={({ field }) => (
                      <Select
                        {...field}
                        isDisabled={isLoading}
                        styles={style}
                        placeholder={"Select Category"}
                        options={category}
                        onChange={(e) => {
                          field.onChange(e);
                        }}
                      />
                    )}
                  />
                  {errors.second_damage_classification_id && (
                    <div className="error_line">Category is required</div>
                  )}
                </div>
                <div className="col-sm-12">
                  <label className="form-label">
                    Sub Category <span className="fw-bold text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    {...register("name", { required: true })}
                    disabled={isLoading}
                  />
                  {errors.name && (
                    <div className="error_line">Name is required</div>
                  )}
                </div>

                <div className="col-6 col-sm-6">
                  <label className="form-label">Min</label>
                  <input
                    type="number"
                    className="form-control"
                    {...register("min", {
                      pattern: {
                        value: /^\d+$/,
                        message: "Invalid Min Value",
                      },
                    })}
                    disabled={isLoading}
                  />
                  {errors.min && (
                    <div className="error_line">{errors.min.message}</div>
                  )}
                </div>
                <div className="col-6 col-sm-6">
                  <label className="form-label">Max</label>
                  <input
                    type="number"
                    className="form-control"
                    {...register("max", {
                      pattern: {
                        value: /^\d+$/,
                        message: "Invalid Max Value",
                      },
                      min: {
                        value: !!watch("min") ? watch("min") : 1,
                        message: "Max value should be greater than min value",
                      },
                    })}
                    disabled={isLoading}
                  />
                  {errors.max && (
                    <div className="error_line">{errors.max.message}</div>
                  )}
                </div>
                {/* <div className="col-sm-4">
                  <label className="form-label">Measurement Unit</label>
                  <input
                    type="text"
                    className="form-control"
                    {...register("measurement_unit", { required: true })}
                    disabled={isLoading}
                  />
                  {errors.measurement_unit && (
                    <div className="error_line">
                      Measurement Unit is required
                    </div>
                  )}
                </div> */}

                <div className="col-sm-6">
                  <label className="form-label">
                    Amount per unit{" "}
                    <span className="fw-bold text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    {...register("amount_per_unit", {
                      required: "Amount per unit is required",
                      pattern: {
                        value: /^\d+$/,
                        message: "Invalid amount",
                      },
                    })}
                    disabled={isLoading}
                  />
                  {errors.amount_per_unit && (
                    <div className="error_line">
                      {errors.amount_per_unit.message}
                    </div>
                  )}
                </div>

                <div className="col-sm-6">
                  <label className="form-label">
                    Status <span className="fw-bold text-danger">*</span>
                  </label>
                  <select
                    className="form-select"
                    {...register("status", { required: true })}
                    disabled={isLoading}
                  >
                    <option value="">Select Status</option>
                    <option value="Active">Active</option>
                    <option value="Deactive">Deactive</option>
                  </select>
                  {errors.status && (
                    <div className="error_line">Status is required</div>
                  )}
                </div>

                <div className="col-12">
                  <div>
                    <input
                      type="submit"
                      value={damageUpdate.isUpdating ? "Update" : "Submit"}
                      className="btn btn-primary"
                      disabled={isLoading}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SubCategory;
