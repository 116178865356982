import StatusBadge from "../../SDRFManagement/Application/Components/StatusBadge";
export const getCustomColumns = (d) => {
  let columns = [];
  // console.log(d);
  // console.log(customFieldList);
  for (let value of d) {
    let keyname = value["server_value"];
    if (keyname === "status_id") {
      columns.push({
        name: value.label,
        selector: (row) => <StatusBadge status={row['status_id']} />,
        sortable: true,
      });
    } else {
      columns.push({
        name: value.label,
        selector: (row) => row[keyname],
        sortable: true,
      });
    }
  }

  return columns;
};
