import React from "react";
import GifImg from "../../../assets/images/loader.gif";

const Loader = ({ title }) => {
  return (
    <div className="loaderBox">
      <img src={GifImg} alt="Loader" />
      <p>{!!title ? title : "Loading..."}</p>
    </div>
  );
};

export default Loader;
