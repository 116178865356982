import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "./Sidebar";
import Header from "./Header";
import Footer from "./Footer";

const Layout = () => {
  const [isBarOpen, setIsBarOpen] = useState(false);
  const [isBarHover, setIsBarHover] = useState(false);
  const toggleBarBtn = () => {
    setIsBarOpen(!isBarOpen);
  };
  return (
    <>
      <section className="pagesec">
        <Sidebar
          isBarOpen={isBarOpen}
          setIsBarOpen={setIsBarOpen}
          isBarHover={isBarHover}
          setIsBarHover={setIsBarHover}
        />
        <div className={`sidecontentmain ${isBarOpen ? "active" : ""}`}>
          <div className="sidecontent">
            <Header toggleBarBtn={toggleBarBtn} />
            <div className="contentbox p-3">
              <Outlet />
            </div>
          </div>
        </div>
        <Footer />
      </section>
    </>
  );
};

export default Layout;
