import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

const Auth = (props) => {
  const { redirectPath  } = props;
  const isAdmin = useSelector((state) => state.authentication.isAdmin);
  if (isAdmin) {
    return <Outlet />;
  }
  return <Navigate to={redirectPath} />;
};

export default Auth;
