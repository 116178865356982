import React, { useEffect, useState, createContext } from "react";
import Api from "../../../config/Api";
import { toast } from "react-toastify";
import { useForm, useFieldArray, FormProvider } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import GifImg from "../../../assets/images/loader.gif";
import { FiPlus } from "react-icons/fi";
import Authentications from "./Components/Authentications";
import { FaArrowLeft } from "react-icons/fa";

export const DamageContext = createContext({
  remove: () => {},
  append: () => {},
});

const Sdrf3 = () => {
  const methods = useForm({
    defaultValues: {
      authentications: [
        {
          name: "",
          designation: "",
        },
      ],
    },
  });
  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = methods;

  const navigate = useNavigate();
  const location = useLocation();

  const { id, isEdit } = location.state || {};
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [applicationImage, setApplicationImage] = useState("");

  //setDataInEdit
  const setDataInEdit = async () => {
    try {
      // setIsFetching(true);
      const body = {
        id,
      };
      const res = await Api("view_applicant_details", "POST", body);
      // console.log(res.data.ApplicantDetails);
      if (res.data.status === 1) {
        // setDetailData(res.data.ApplicantDetails);
        const VerifyingOfficer = res.data.ApplicantDetails.verifying_officer;
        // console.log(VerifyingOfficer);

        let tempAuthenticationsArray;

        if (VerifyingOfficer.length > 0) {
          tempAuthenticationsArray = VerifyingOfficer.map((a) => {
            return {
              name: a?.name,
              designation: a?.designation,
            };
          });

          setApplicationImage(VerifyingOfficer[0]?.application_file);
        } else {
          tempAuthenticationsArray = [
            {
              name: "",
              designation: "",
            },
          ];

          setApplicationImage({});
        }

        let tempResetObj = {
          authentications: tempAuthenticationsArray,
        };
        reset(tempResetObj);
        // setIsFetching(false);
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsFetching(false);
      }
    } catch (error) {
      toast.error(error.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
  //setDataInEdit

  const { append, remove, fields } = useFieldArray({
    control,
    name: "authentications",
  });

  const addNewDamageField = (d) => {
    append(
      {
        name: "",
        designation: "",
      },
      {
        shouldFocus: true,
      }
    );
  };


  useEffect(() => {
    if (isEdit) {
      setDataInEdit();
    }
    // eslint-disable-next-line
  }, [isEdit]);

  useEffect(() => {
    if (!id) {
      navigate(-1);
    }
    // eslint-disable-next-line
  }, [id]);


  const onSubmit = async (d, e) => {
    try {
      setIsLoading(true);
      // console.log(d);
      if (!id) {
        toast.error("Application ID Invalid", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsLoading(false);
        return;
      }

      if (!d?.authentications?.length || !d?.authentications) {
        toast.error("Please add atleast one damage", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsLoading(false);
        return;
      }
   const formData = new FormData();
      let authenticationsList = [];
      for (var i = 0; i < d?.authentications.length; i++) {
        authenticationsList.push({
          name: d?.authentications[i].name,
          designation: d?.authentications[i].designation,
        });
      }

      // let body = {
      //   application_id: id,
      //   authentications: authenticationsList,
      // };
      const authenticationsJson = JSON.stringify(authenticationsList);

      formData.append('application_id' ,id);
      formData.append('authentications' ,authenticationsJson);
      if(d?.application_file[0]){
      formData.append("application_file", d?.application_file[0]);
      }else{
        console.log(applicationImage);
        const parts = applicationImage?.split('/');
        const lastPart = parts[parts?.length - 1];
        const filename = lastPart.split('?')[0];
        // console.log(filename);
        formData.append("old_application_file", filename);
      }

      const res = await Api("application-third-save", "POST", formData);
      // console.log(res);
      if (res.data.status === 1) {
        setIsLoading(false);
        navigate("/sdrf-submitted", {
          state: {
            id: res.data.data,
          },
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsLoading(false);
      }
    } catch (error) {
      toast.error(error, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  return (
    <>
      <div className={`loaderoverlay ${isFetching && "show"}`}>
        <div>
          <img src={GifImg} alt="Loader" />
          <p>Loading...</p>
        </div>
      </div>
      <div className="bg-white p-3 rounded p-3 shadow">
        <div className="d-flex justify-content-between mb-3">
        <div>
          <button className="btn btn-primary" onClick={()=>navigate(-1)}><FaArrowLeft/> Back</button>
          </div>
          <div className="">
            <h5 className="text-dark">Authentication</h5>
          </div>
        </div>
        <div className="steps_row mb-4">
          <ul>
            <li className="steps_box active">
              <div>
                <span className="steps_circle"></span>
                <p>Personal Details</p>
              </div>
            </li>
            <li className="steps_box active">
              <div>
                <span className="steps_circle"></span>
                <p>Damage Report</p>
              </div>
            </li>
            <li className="steps_box active">
              <div>
                <span className="steps_circle"></span>
                <p>Authentication</p>
              </div>
            </li>
          </ul>
        </div>

        <div className="d-sm-flex justify-content-between align-items-center mb-3">
          <div className="flex-grow-1">
            <h6 className="text-dark text-uppercase small fw-bold">
              Application verified by
            </h6>
          </div>
          <div className="flex-shrink-0">
            <button
              className="btn btn-primary"
              type="button"
              onClick={addNewDamageField}
            >
              <FiPlus /> Add More
            </button>
          </div>
        </div>
        <div>
          <DamageContext.Provider value={{ remove }}>
            <FormProvider {...methods}>
              <form
                onSubmit={handleSubmit(onSubmit)}
                encType="multipart/form-data"
              >
                <div className="">
                  <div className="row g-3">
                    {fields.map((damage, index) => {
                      return (
                        <div className="col-12" key={damage.id}>
                          <Authentications
                            index={index}
                            isLoading={isLoading}
                            setIsLoading={setIsLoading}
                            setIsFetching={setIsFetching}
                            isEdit={isEdit}
                            damage={damage}
                          />
                        </div>
                      );
                    })}


                <div className="col-lg-4 col-md-12">
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <label className="form-label">
                          Upload Proof of Verification.{" "}
                          <span className="text-danger fw-bold">*</span>
                        </label>
                      </div>
                      {!!applicationImage && (
                        <div className="flex-shrink-0 ms-3">
                          <a
                            target="_blank"
                            href={applicationImage}
                            className="badge bg-primary text-white"
                            rel="noreferrer"
                          >
                            View Image
                          </a>
                        </div>
                   )}
                    </div>
                    <input
                      type="file"
                      className="form-control"
                      {...register("application_file", {
                        required: isEdit ? false : true,
                      })}
                      disabled={isLoading}
                    />

                    {errors.application_file && (
                      <div className="error_line">
                        Proof of Verification is required
                      </div>
                    )}
                  </div>


                    <div className="col-12">
                      <div>
                        <input
                          type="submit"
                          value="Submit"
                          className="btn btn-primary px-5"
                          disabled={isLoading}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </FormProvider>
          </DamageContext.Provider>
        </div>
      </div>
    </>
  );
};

export default Sdrf3;
