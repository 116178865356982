import Counters from "./Components/Counters";
import Graph1 from "./Components/Graph1";
import Graph2 from "./Components/Graph2";

import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  // BarController,
  // BubbleController,
  // DoughnutController,
  // LineController,
  // PieController,
  // PolarAreaController,
  // RadarController,
  // ScatterController,
  CategoryScale,
  LinearScale,
  // LogarithmicScale,
  // RadialLinearScale,
  // TimeScale,
  // TimeSeriesScale,
  // Decimation,
  Filler,
  Legend,
  // Title,
  Tooltip,
  Colors
} from "chart.js";
import Graph3 from "./Components/Graph3";


Chart.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  // BarController,
  // BubbleController,
  // DoughnutController,
  // LineController,
  // PieController,
  // PolarAreaController,
  // RadarController,
  // ScatterController,
  CategoryScale,
  LinearScale,
  // LogarithmicScale,
  // RadialLinearScale,
  // TimeScale,
  // TimeSeriesScale,
  // Decimation,
  Filler,
  Legend,
  // Title,
  Tooltip,
  Colors 
);

const Dashboard = () => {
  return (
    <>
      <div className="">
        <div className="row g-3">
          <div className="col-12">
            <Counters />
          </div>
          <div className="col-12">
            <Graph1 />
          </div>
          <div className="col-12">
            <Graph2 />
          </div>
          <div className="col-12">
            <Graph3 />
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
