import React, { useEffect, useState } from "react";
import Api from "../../../config/Api";
import { Bar } from "react-chartjs-2";
import dayjs from "dayjs";

const Graph3 = () => {
  const [barData, setBarData] = useState({
    labels: [],
    data: [],
  });
  const getGraph = async (did) => {
    const body = {
      district_id: did,
    };
    const { data } = await Api("performance-metrics", "POST", body);
    // console.log(data);
    if (data.status === 1) {
      const labels =
        !!data.data?.length &&
        data.data?.map((a) => dayjs(a.month, "MMMM YYYY").format("MMM YYYY"));
      const dataCounts =
        !!data.data?.length && data.data?.map((a) => Number(a.avg_days));

      setBarData({
        labels: labels,
        data: dataCounts,
      });
      // console.log(dataCounts);
    }
  };

  const data = {
    labels: barData.labels,
    datasets: [
      {
        data: barData.data,
        // backgroundColor: "rgb(34 75 139 / 30%)",
        barThickness: 20,
        borderRadius: 5,
        tension: 0.3,
        fill: "start",
        // borderColor: "rgb(34 75 139 / 90%)",
      },
    ],
  };
  const option = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
        position: "bottom",
        labels: {
          padding: 40,
        },
      },
      colors: {
        forceOverride: true,
      },
    },
    scales: {
      x: {
        // stacked: true,
      },
      y: {
        // stacked: true,
        beginAtZero: true,
        // ticks: {
        //   precision: 0,
        // },
      },
    },
  };

  useEffect(() => {
    getGraph();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="bg-white rounded-4 p-3">
        <div className="mb-2">
          <div className="row">
            <div className="col-lg">
              <h6 className="fw-bold mb-0 text-dark">
                SDRF Performance Metrics
              </h6>
            </div>
          </div>
        </div>
        <div className="mt-3">
          <div className="row g-3">
            <div className="col-lg-12">
              <div className="chartbox">
                <Bar options={option} data={data} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Graph3;
