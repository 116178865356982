import React, { useEffect, useState } from "react";
import Api from "../../config/Api";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import StatusBadge from "../SDRFManagement/Application/Components/StatusBadge";
import { FaRegFileExcel, FaRegFilePdf } from "react-icons/fa";
import TooltipWidget from "../Components/Widget/TooltipWidget";
import Status from "./Components/status.json";
import Table from "../Components/Widget/Table";
import Loader from "../Components/Widget/Loader";

const CalamityReport = () => {
  // const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  // const [isFetching, setIsFetching] = useState(false);
  const [application, setApplication] = useState([]);
  const admin = useSelector((state) => JSON.parse(state.authentication.admin));
  const [natureDisasterList, setNatureDisasterList] = useState([]);
  const [isApplicationLoaded, setIsApplicationLoaded] = useState(false);
  const [filter, setFilter] = useState("");
  const [currentStatus, setCurrentStatus] = useState("");
  const [currentNatureDisaster, setCurrentNatureDisaster] = useState("");

  const natureDisaster = async (e) => {
    const res = await Api("nature_of_disaster_get");
    setNatureDisasterList(res?.data?.NatureOfDisaster);
    //console.log(res);
  };

  useEffect(() => {
    natureDisaster();
  }, []);

  //Application Get
  const getApplication = async (status_id, nature_disaster) => {
    try {
      setIsLoading(true);
      const body = {};
      if (Boolean(status_id)) {
        body.status = status_id;
      }
      if (Boolean(nature_disaster)) {
        body.nature_disaster = nature_disaster;
      }

      // console.log(body);
      const res = await Api("sdrf_report_per_calamity", "POST", body, true);
      // console.log(res);
      if (res.data.status === 1) {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        setApplication(res.data.data);
        setIsApplicationLoaded(true);
        setIsLoading(false);
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsLoading(false);
      }
    } catch (error) {
      toast.error(error.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
  //Application Get

  //filter
  const searchInput = (e) => {
    setFilter(e.target.value);
  };

  const filtered = !filter
    ? application
    : application.filter(
        (application) =>
          application?.application_id
            ?.toLowerCase()
            .includes(filter.toLowerCase()) ||
          application?.applicant_name
            ?.toLowerCase()
            .includes(filter.toLowerCase()) ||
          application?.mobile?.toLowerCase().includes(filter.toLowerCase())
      );

  useEffect(() => {
    getApplication(currentStatus);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterStatus = (e) => {
    if (!!e.target.value) {
      setIsApplicationLoaded(false);
      setCurrentStatus(e.target.value);
      getApplication(e.target.value, currentNatureDisaster);
    } else {
      setIsApplicationLoaded(false);
      getApplication("", currentNatureDisaster);
      setCurrentStatus("");
    }
  };

  const filterNatureDisaster = (e) => {
    if (!!e.target.value) {
      setIsApplicationLoaded(false);
      getApplication(currentStatus, e.target.value);
      setCurrentNatureDisaster(e.target.value);
    } else {
      setIsApplicationLoaded(false);
      getApplication(currentStatus);
      setCurrentNatureDisaster("");
    }
  };

  const downloadExcel = async () => {
    try {
      let baseUrl = process.env.REACT_APP_API_URL;
      let endpoint = "export_sdrf_report_per_calamity";

      let nature_disaster = !!currentNatureDisaster
        ? `nature_disaster[]=${currentNatureDisaster}`
        : null;
      let status = !!currentStatus ? `status[]=${currentStatus}` : null;
      let url;
      if (Boolean(currentNatureDisaster)) {
        url = `${baseUrl}${endpoint}?${nature_disaster}&${status}`;
      } else {
        url = `${baseUrl}${endpoint}?${status}`;
      }
      window.open(url, "_blank");
    } catch (error) {
      toast.error("Something went wrong!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
  const downloadPdf = () => {
    //console.log("a");
    try {
      let baseUrl = process.env.REACT_APP_API_URL;
      let endpoint = `pdf-sdrfreport-per-calamity/${admin.id}`;
      let nature_disaster = !!currentNatureDisaster
        ? `nature_disaster[]=${currentNatureDisaster}`
        : null;
      let status = !!currentStatus ? `status[]=${currentStatus}` : null;
      let url;
      if (Boolean(currentNatureDisaster)) {
        url = `${baseUrl}${endpoint}?${nature_disaster}&${status}`;
      } else {
        url = `${baseUrl}${endpoint}?${status}`;
      }
      window.open(url, "_blank");
    } catch (error) {
      toast.error("Something went wrong!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  const columns = [
    {
      name: "#",
      selector: (props) => filtered.indexOf(props) + 1,
      sortable: true,
      width: "50px",
    },
    {
      name: "Application ID",
      selector: (row) => row.application_id,
      sortable: true,
      maxWidth: "150px",
    },
    {
      name: "Applicant Name",
      selector: (row) => row.applicant_name,
      sortable: true,
      maxWidth: "150px",
    },
    {
      name: "Nature Of Disaster",
      selector: (row) => row?.nature?.map((item) => item?.title).join(", "),
      sortable: true,
      maxWidth: "150px",
    },
    {
      name: "Item Damaged",
      selector: (row) =>
        row?.application_third_meta
          ?.map((item) => item?.first_damage_classification?.title)
          .join(", "),
      sortable: true,
      maxWidth: "150px",
    },
    {
      name: "Category",
      selector: (row) =>
        row?.application_third_meta
          ?.map((item) => item?.second_damage_classification?.damage_name)
          .join(", "),
      sortable: true,
      maxWidth: "150px",
    },
    {
      name: "Date Of Occurrence",
      selector: (row) => row.date_of_occurrence,
      sortable: true,
    },
    {
      name: "District",
      selector: (row) => row.district?.district_name,
      sortable: true,
      maxWidth: "100px",
    },
    {
      name: "Status",
      selector: (row) => <StatusBadge status={row.status} />,
      sortable: true,
      maxWidth: "150px",
    },
  ];

  return (
    <>
      <div className="bg-white p-3 rounded p-3 shadow">
        <div className="mb-3">
          <div className="row g-2">
            <div className="col-xl-4">
              <h5 className="text-dark">Generate Report By Calamity</h5>
            </div>

            <div className="col-xl-8">
              <div className="">
                <div className="row g-2">
                  <div className="col-sm-6 col-lg-3">
                    <div className="">
                      <select
                        className="form-select"
                        name="title"
                        onChange={(e) => filterNatureDisaster(e)}
                        disabled={isLoading}
                      >
                        <option value="">Select Disaster</option>
                        {natureDisasterList
                          ?.filter((NatureOfDisaster) => {
                            return NatureOfDisaster.status === "Active";
                          })
                          .map((NatureOfDisaster) => {
                            return (
                              <option
                                value={NatureOfDisaster?.id}
                                key={NatureOfDisaster?.id}
                              >
                                {NatureOfDisaster?.title}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-6 col-lg-3">
                    <div className="">
                      <select
                        className="form-select"
                        name="status"
                        onChange={(e) => filterStatus(e)}
                        disabled={isLoading}
                      >
                        <option value="">All Status</option>
                        {Status?.map((item) => {
                          return (
                            <option key={item.value} value={item.value}>
                              {item.label}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-6 col-lg-3">
                    <div className="">
                      <input
                        type="text"
                        placeholder="Search Application"
                        className="form-control"
                        value={filter}
                        onChange={searchInput}
                        disabled={isLoading}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 col-lg-3">
                    <div className="d-flex gap-2">
                      <TooltipWidget title="Download Excel">
                        <button
                          onClick={downloadExcel}
                          className="btn btn-success flex-grow-1"
                          type="button"
                        >
                          <FaRegFileExcel />
                        </button>
                      </TooltipWidget>
                      <TooltipWidget title="Download PDF">
                        <button
                          onClick={downloadPdf}
                          className="btn btn-danger  flex-grow-1"
                          type="button"
                        >
                          <FaRegFilePdf />
                        </button>
                      </TooltipWidget>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          {isApplicationLoaded ? (
            <Table columns={columns} data={filtered} />
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </>
  );
};

export default CalamityReport;
