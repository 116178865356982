import React from "react";
import Routing from "./Navigation";
import ScrollToTop from "./Views/Components/ScrollToTop";
import ApiLoader from "./Views/Components/Widget/ApiLoader";

const App = () => {
  return (
    <>
      <ApiLoader />
      <ScrollToTop />
      <Routing />
    </>
  );
};

export default App;
