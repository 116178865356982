import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Api from "../../config/Api";
import { useDispatch, useSelector } from "react-redux";
import { setAuth } from "../../Reducers/Authentication";
import Logo from "../../assets/images/logo.png";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

const Index = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    // reset,
  } = useForm();

  const isAdmin = useSelector((state) => state.authentication.isAdmin);
  const admin = useSelector((state) => JSON.parse(state.authentication.admin));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isAdmin) {
      if (admin?.role?.permission?.includes("Home")) {
        navigate("/home");
      } else {
        navigate("/dashboard");
      }
    }
    // eslint-disable-next-line
  }, [isAdmin]);

  //Login Submit Function
  const onSubmit = async (d) => {
    try {
      // console.log(d);
      setIsLoading(true);
      const body = {
        email: d.email,
        password: d.password,
      };
      // console.log(d);
      // console.log(body);
      const res = await Api("admin-login", "POST", body);
      // console.log(res.data?.UserDetails);
      if (res.data.status === 1) {
        dispatch(setAuth(res.data.UserDetails));
        if (res.data?.UserDetails?.role?.permission.includes("Home")) {
          navigate("/home");
        } else {
          navigate("/dashboard");
        }
        setIsLoading(false);
        toast.success("Login Successful", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsLoading(false);
      }
    } catch (error) {
      toast.error("Something went wrong!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  return (
    <>
      <section className="loginpage ">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-4 col-lg-5 col-md-6 col-sm-8">
              <div className="homeloginbox p-3 p-lg-4">
                <div className="row  align-items-center">
                  <div className="col-12">
                    <div className="homelogo">
                      <Link to="/">
                        <img src={Logo} alt="logo" />
                      </Link>
                    </div>
                    <h5 className="text-center mb-4 text-dark">Login</h5>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="row g-3">
                        <div className="col-lg-12">
                          <div className="input-box">
                            <input
                              type="text"
                              placeholder="Username"
                              disabled={isLoading}
                              {...register("email", {
                                required: "Username is required",
                              })}
                            />
                            {errors.email && (
                              <div className="error_line">
                                {errors.email.message}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="input-box">
                            <input
                              type="password"
                              placeholder="Password"
                              disabled={isLoading}
                              {...register("password", {
                                required: "Password is required",
                              })}
                            />
                            {errors.password && (
                              <div className="error_line">
                                {errors.password.message}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="text-center">
                            <button
                              type="submit"
                              className="btn btn-primary w-100"
                              disabled={isLoading}
                            >
                              {isLoading ? "Loading..." : "Login"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Index;
