import React from "react";
import { Route } from "react-router-dom";

import ItemDamaged from "../Views/Master/ItemDamaged";
import Category from "../Views/Master/Category";
import SubCategory from "../Views/Master/SubCategory";

import Sdrf1 from "../Views/SDRFManagement/SDRF/Sdrf1";
import Sdrf2 from "../Views/SDRFManagement/SDRF/Sdrf2";
import Sdrf3 from "../Views/SDRFManagement/SDRF/Sdrf3";

import ManageApplications from "../Views/SDRFManagement/ManageSDRF";
import VerifyApplications from "../Views/SDRFManagement/VerifySDRF";
import RejectedApplications from "../Views/SDRFManagement/RejectedSDRF";
import ReliefApproveApplications from "../Views/SDRFManagement/ReliefApproveSDRF";
import HodApproveApplications from "../Views/SDRFManagement/HodApproveSDRF";
import Application from "../Views/SDRFManagement/Application";

import ManageRole from "../Views/User/ManageRole";
import ManageUser from "../Views/User/ManageUser";
import NatureOfDisaster from "../Views/Master/NatureOfDisaster";
import SdrfSubmitted from "../Views/SDRFManagement/SDRF/SdrfSubmitted";
import SdrfDocuments from "../Views/SDRFManagement/SDRF/SdrfDocuments";
import Home from "../Views/Dashboard";
import Dashboard from "../Views/Dashboard/Dashboard";
import GenerateReport from "../Views/SDRFReport/GenerateReport";
import CustomReport from "../Views/SDRFReport/CustomReport";
import CalamityReport from "../Views/SDRFReport/CalamityReport";
import PropertyReport from "../Views/SDRFReport/PropertyReport";
import HistoricalReport from "../Views/SDRFReport/HistoricalReport";
import GraphicalReport from "../Views/SDRFReport/GraphicalReport";

const useRoute = (permission) => {
  const accessRoutes = [];
  const accessRouteHome = [];

  if (permission) {
    // eslint-disable-next-line
    let filterAccessRoutes = permission.map((a, i) => {
      if (a !== "Home") {
        if (a === "Dashboard") {
          return <Route path="dashboard" element={<Dashboard />} />;
        } else if (a === "Add SDRF" || a === "Edit SDRF") {
          return (
            <>
              <Route path="sdrf-1" element={<Sdrf1 />} />
              <Route path="sdrf-2" element={<Sdrf2 />} />
              <Route path="sdrf-3" element={<Sdrf3 />} />
              <Route path="sdrf-submitted" element={<SdrfSubmitted />} />
              <Route path="sdrf-documents" element={<SdrfDocuments />} />
              <Route path="application/:id" element={<Application />} />
            </>
          );
        } else if (a === "SDRF Report") {
          return (
            <>
              <Route path="/generate-report" element={<GenerateReport />} />
              <Route path="/custom-report" element={<CustomReport />} />
              <Route path="/calamity-report" element={<CalamityReport />} />
              <Route path="/property-report" element={<PropertyReport />} />
              <Route
                path="/historical-report"
                element={<HistoricalReport />}
              />
               <Route
                path="/graphical-report"
                element={<GraphicalReport />}
              />
            </>
          );
        } else if (a === "Manage SDRF") {
          return (
            <>
              <Route
                path="/manage-applications"
                element={<ManageApplications />}
              />
              <Route path="application/:id" element={<Application />} />
            </>
          );
        } else if (a === "Rejected SDRF") {
          return (
            <>
              <Route
                path="/rejected-applications"
                element={<RejectedApplications />}
              />
              <Route path="application/:id" element={<Application />} />
            </>
          );
        } else if (a === "Nodal Officer") {
          return (
            <>
              <Route
                path="/verify-applications"
                element={<VerifyApplications />}
              />
              <Route path="application/:id" element={<Application />} />
            </>
          );
        } else if (a === "Relief Officer") {
          return (
            <>
              <Route
                path="/relief-approve-applications"
                element={<ReliefApproveApplications />}
              />
              <Route path="application/:id" element={<Application />} />
            </>
          );
        } else if (a === "HOD") {
          return (
            <>
              <Route
                path="/hod-approve-applications"
                element={<HodApproveApplications />}
              />
              <Route path="application/:id" element={<Application />} />
            </>
          );
        } else if (a === "Nature Of Disaster") {
          return (
            <Route path="nature-of-disaster" element={<NatureOfDisaster />} />
          );
        } else if (a === "Item Damaged") {
          return <Route path="item-damaged" element={<ItemDamaged />} />;
        } else if (a === "Category") {
          return <Route path="category" element={<Category />} />;
        } else if (a === "Sub Category") {
          return <Route path="sub-category" element={<SubCategory />} />;
        } else if (a === "Manage Role") {
          return <Route path="manage-role" element={<ManageRole />} />;
        } else if (a === "Manage User") {
          return <Route path="manage-user" element={<ManageUser />} />;
        } else {
          return true;
        }
      } else {
        let home = <Route path="home" element={<Home />} key={i} />;
        accessRouteHome.push(home);
      }
    });

    accessRoutes.push(filterAccessRoutes);
    return { accessRoutes, accessRouteHome };
  }

  return "";
};

export default useRoute;
