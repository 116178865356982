import React, { useEffect, useState } from "react";
import Api from "../../config/Api";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { FaRegFileExcel, FaRegFilePdf } from "react-icons/fa";
import TooltipWidget from "../Components/Widget/TooltipWidget";
import Loader from "../Components/Widget/Loader";
import { useForm } from "react-hook-form";
// import DatePicker from "react-multi-date-picker";
// import dayjs from "dayjs";
import { createSearchParams } from "react-router-dom";

import DataTable from "react-data-table-component";
import { BsFillCaretDownFill } from "react-icons/bs";
import ModalBox from "../Components/Widget/ModalBox";

const HistoricalReport = () => {
  const { handleSubmit, register, getValues } = useForm();
  const { handleSubmit: handleSubmit1, register: register1, reset, formState:{errors:errors1} } = useForm();
  // const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [applicationList, setApplicationList] = useState([]);
  const [applicationData, setApplicationData] = useState("");
  const admin = useSelector((state) => JSON.parse(state.authentication.admin));
  const [isApplicationLoaded, setIsApplicationLoaded] = useState(false);
  const [filter, setFilter] = useState("");
  const [isPdfDownloadModalShow, setIsPdfDownloadModalShow] = useState(false);
  const [isExcelDownloadModalShow, setIsExcelDownloadModalShow] = useState(false);
  const [pdfNumberList, setPdfNumberList] = useState([]);

  //Application Get
  const getApplication = async (page, bodyData, newPerPage) => {
    try {
      setIsLoading(true);
      const body = {
        page,
        per_page: newPerPage,
      };
      if (!!bodyData) {
        for (var key in bodyData) {
          if (!!bodyData[key]) {
            body[`${key}`] = bodyData[key];
          }
        }
      }
      const res = await Api("historical-report", "POST", body, true);
      // console.log(res);
      if (res.data.status === 1) {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        setApplicationList(res.data.data.data);
        setApplicationData(res.data.data);
        setIsApplicationLoaded(true);
        setIsLoading(false);
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      // toast.error(error.data.message, {
      //   position: toast.POSITION.BOTTOM_RIGHT,
      // });
    }
  };
  //Application Get

  //filter
  const searchInput = (e) => {
    setFilter(e.target.value);
  };

  const filtered = !filter
    ? applicationList
    : applicationList.filter(
        (application) =>
          application?.form_sl_no
            ?.toLowerCase()
            .includes(filter.toLowerCase()) ||
          application?.name?.toLowerCase().includes(filter.toLowerCase()) ||
          application?.aadhar_card
            ?.toLowerCase()
            .includes(filter.toLowerCase()) ||
          application?.gender?.toLowerCase().includes(filter.toLowerCase()) ||
          application?.nature_of_calamity
            ?.toLowerCase()
            .includes(filter.toLowerCase()) ||
          application?.item_damaged
            ?.toLowerCase()
            .includes(filter.toLowerCase()) ||
          application?.extent_of_damage
            ?.toLowerCase()
            .includes(filter.toLowerCase()) ||
          application?.district?.toLowerCase().includes(filter.toLowerCase()) ||
          application?.village?.toLowerCase().includes(filter.toLowerCase()) ||
          application?.amount?.toLowerCase().includes(filter.toLowerCase())
      );

  const downloadExcel = async (range) => {
    try {
      let start_no = range.split("-")[0];
      let end_no = range.split("-")[1];
      let baseUrl = process.env.REACT_APP_API_URL;
      let endpoint = "export_historical_report";

      let newObj =  {
        start_no,
        end_no,
      };
      Object.keys(getValues()).forEach((prop) => {
        if (Boolean(getValues()[prop])) {
          newObj[prop] = getValues()[prop];
        }
      });
      let searchparams = createSearchParams(newObj).toString();
      let url = `${baseUrl}${endpoint}${
        !!searchparams ? `?${searchparams}` : ""
      }`;

      // console.log(url);
      window.open(url, "_blank");
    } catch (error) {
      toast.error("Something went wrong!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
  const downloadPdf = (range) => {
    try {
      let start_no = range.split("-")[0];
      let end_no = range.split("-")[1];
      let baseUrl = process.env.REACT_APP_API_URL;
      let endpoint = `pdf-historical-report/${admin.id}`;

      let newObj = {
        start_no,
        end_no,
      };
      Object.keys(getValues()).forEach((prop) => {
        if (Boolean(getValues()[prop])) {
          newObj[prop] = getValues()[prop];
        }
      });
      //   console.log(newObj);

      let searchparams = createSearchParams(newObj).toString();
      let url = `${baseUrl}${endpoint}${
        !!searchparams ? `?${searchparams}` : ""
      }`;
      //   console.log(url);
      window.open(url, "_blank");
    } catch (error) {
      toast.error("Something went wrong!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  const caseInsensitiveSort = (rowA, rowB) => {
    const a = rowA.amount.toLowerCase();
    const b = rowB.amount.toLowerCase();
    return a - b;
  };

  const columns = [
    {
      name: "#",
      selector: (props) => filtered.indexOf(props) + 1,
      sortable: true,
      width: "50px",
    },
    {
      name: "Form Sr. No.",
      selector: (row) => (!!row.form_sl_no ? row.form_sl_no : "-"),
      sortable: true,
      width: "100px",
    },
    {
      name: "Applicant Name",
      selector: (row) => (!!row.name ? row.name : "-"),
      sortable: true,
    },
    // {
    //   name: "Aadhar Card",
    //   selector: (row) => (!!row.aadhar_card ? row.aadhar_card : "-"),
    //   sortable: true,
    //   maxWidth: "150px",
    // },
    {
      name: "Gender",
      selector: (row) => (!!row.gender ? row.gender : "-"),
      sortable: true,
      width: "80px",
    },
    {
      name: "Nature Of Calamity",
      selector: (row) =>
        !!row?.nature_of_calamity ? row?.nature_of_calamity : "-",
      sortable: true,
    },
    {
      name: "Item Damaged",
      selector: (row) => (!!row?.item_damaged ? row?.item_damaged : "-"),
      sortable: true,
    },
    {
      name: "Extent Of Damage",
      selector: (row) => (row?.extent_of_damage ? row?.extent_of_damage : "-"),
      sortable: true,
    },
    {
      name: "Date Of Occurrence",
      selector: (row) =>
        !!row.date_of_occurance ? row.date_of_occurance : "-",
      sortable: true,
    },
    {
      name: "District",
      selector: (row) => (!!row.district ? row.district : "-"),
      sortable: true,
    },
    {
      name: "Village",
      selector: (row) => (!!row.village ? row.village : "-"),
      sortable: true,
    },
    {
      name: "Amount",
      selector: (row) => (!!row.amount ? row.amount : "-"),
      sortable: true,
      maxWidth: "100px",
      sortFunction: caseInsensitiveSort,
    },
  ];

  const customStyles = {
    table: {
      style: {
        border: "1px solid rgba(0,0,0,.12)",
      },
    },
    headCells: {
      style: {
        padding: "5px",
        "&:not(:last-child)": {
          borderRight: "1px solid rgba(0,0,0,.12)",
        },
      },
    },
    cells: {
      style: {
        padding: "5px",
        wordBreak: "break-word",
        lineHeight: "1.4",
        "&:not(:last-child)": {
          borderRight: "1px solid rgba(0,0,0,.12)",
        },
      },
    },
    rows: {
      style: {
        borderColor: "#000",
        "&:hover": {
          outline: "0 !important",
          borderColor: "rgba(0,0,0,.12) !important",
        },
      },
    },
  };

  const pdfDownloadModalShow = () => {
    setIsPdfDownloadModalShow(true);
  };

  const pdfDownloadModalclose = () => {
    setIsPdfDownloadModalShow(false);
  };

  const excelDownloadModalShow = () => {
    setIsExcelDownloadModalShow(true);
  };

  const excelDownloadModalclose = () => {
    setIsExcelDownloadModalShow(false);
  };

  const onSubmit = (data) => {
    getApplication(1, data);
  };

  const handlePageChange = (page) => {
      // console.log(newPerPage, page);
      // console.log(getValues());
    getApplication(page, getValues());
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    getApplication(1, {}, newPerPage);
  };

  useEffect(() => {
    getApplication(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setPdfList = () => {
    let rangeSize = 200;
    let maxValue = applicationData?.total;
    const result = [];
    for (let i = 1; i <= maxValue; i += rangeSize) {
      const start = i;
      const end = Math.min(i + rangeSize - 1, maxValue);
      const rangeString = `${start}-${end}`;
      result.push(rangeString);
    }

    setPdfNumberList(result);
  };

  const onDownloadPdf = d => {
    downloadPdf(d?.range);
  }
  const onDownloadExcel = d => {
    downloadExcel(d?.range);
  }

  useEffect(() => {
    if (!!applicationData?.total) {
      setPdfList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationData?.total]);

  return (
    <>
      <div className="bg-white p-3 rounded p-3 shadow">
        <div className="justify-content-between mb-3 gap-2">
          <div className="flex-grow-1">
            <h5 className="text-dark">Historical Report</h5>
          </div>
          <div className="flex-grow-1">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row g-2">
                <div className="col-lg-3 col-sm-6 col-md-4">
                  <div className="">
                    <label className="form-label">Nature of Calamity</label>
                    <input
                      className="form-control"
                      type="text"
                      {...register("nature_of_calamity")}
                      disabled={isLoading}
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6  col-md-4">
                  <div className="">
                    <label className="form-label">District</label>
                    <input
                      className="form-control"
                      type="text"
                      {...register("district")}
                      disabled={isLoading}
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6  col-md-4">
                  <div className="">
                    <label className="form-label">Item Damaged</label>
                    <input
                      className="form-control"
                      type="text"
                      {...register("item_damaged")}
                      disabled={isLoading}
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6  col-md-4">
                  <div className="">
                    <label className="form-label">Extent of Damage</label>
                    <input
                      className="form-control"
                      type="text"
                      {...register("extent_of_damage")}
                      disabled={isLoading}
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6  col-md-4">
                  <div className="">
                    <label className="form-label">Gender</label>
                    <select
                      className="form-select"
                      {...register("gender")}
                      disabled={isLoading}
                    >
                      <option value="">Select Gender</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Others">Others</option>
                    </select>
                  </div>
                </div>
                {/* <div className="col-lg-3 col-sm-6 col-md-4">
                  <div className="">
                    <label className="form-label">Start Date</label>
                    <Controller
                      control={control}
                      name="start_date"
                      render={({ field }) => (
                        <DatePicker
                          {...field}
                          inputClass="form-control"
                          // placeholder="Start Date"
                          maxDate={new Date()}
                          format="YYYY-MM-DD"
                          editable={true}
                          value={field.value || ""}
                          onChange={(date) => {
                            field.onChange(date?.format?.("YYYY-MM-DD"));
                            // startDateChange(date?.format?.("YYYY-MM-DD"));
                          }}
                          disabled={isLoading}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 col-md-4">
                  <div className="">
                    <label className="form-label">End Date</label>
                    <Controller
                      control={control}
                      name="end_date"
                      render={({ field }) => (
                        <DatePicker
                          {...field}
                          inputClass="form-control"
                          // placeholder="End Date"
                          maxDate={new Date()}
                          minDate={dayjs(watch("start_date"), "YYYY-MM-DD").$d}
                          format="YYYY-MM-DD"
                          editable={true}
                          onChange={(date) => {
                            field.onChange(date?.format?.("YYYY-MM-DD"));
                            // endDateChange(date?.format?.("YYYY-MM-DD"));
                          }}
                          disabled={isLoading}
                        />
                      )}
                    />
                  </div>
                </div> */}
                <div className="col-lg-3 col-sm-6  col-md-8">
                  <div className="">
                    <label className="form-label">Search</label>
                    <input
                      type="text"
                      className="form-control"
                      value={filter}
                      onChange={searchInput}
                      disabled={isLoading}
                    />
                  </div>
                </div>
                <div className="col-lg-3  col-md-4">
                  <label className="form-label"></label>
                  <div className="d-flex gap-2">
                    <button
                      className="btn btn-primary flex-grow-1"
                      type="submit"
                      disabled={isLoading}
                    >
                      Show
                    </button>
                    <TooltipWidget title="Download Excel">
                      <button
                        //onClick={downloadExcel}
                        onClick={excelDownloadModalShow}
                        className="btn btn-success flex-grow-1"
                        type="button"
                        disabled={isLoading}
                      >
                        <FaRegFileExcel />
                      </button>
                    </TooltipWidget>
                    <TooltipWidget title="Download PDF">
                      <button
                        onClick={pdfDownloadModalShow}
                        className="btn btn-danger  flex-grow-1"
                        type="button"
                        disabled={isLoading}
                      >
                        <FaRegFilePdf />
                      </button>
                    </TooltipWidget>
                    {/* <TooltipWidget title="Download PDF">
                      <button
                        onClick={downloadPdf}
                        className="btn btn-danger  flex-grow-1"
                        type="button"
                        disabled={isLoading}
                      >
                        <FaRegFilePdf />
                      </button>
                    </TooltipWidget> */}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div>
          {isApplicationLoaded ? (
            // <Table columns={columns} data={filtered} />
            <DataTable
              columns={columns}
              data={filtered}
              sortIcon={<BsFillCaretDownFill />}
              customStyles={customStyles}
              defaultSortFieldId={1}
              striped
              highlightOnHover
              pagination
              dense
              progressComponent={<Loader />}
              progressPending={isLoading}
              paginationServer
              paginationTotalRows={applicationData?.total}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
            />
          ) : (
            <Loader />
          )}
        </div>
      </div>

      <ModalBox
        show={isPdfDownloadModalShow}
        hide={pdfDownloadModalclose}
        title="Choose range for download PDF"
      >
        <div className="">
          <form onSubmit={handleSubmit1(onDownloadPdf)}>
            <div className="row g-3 justify-content-center">
              <div className="col-md-12">
                <div className="">
                  <select className="form-select" {...register1('range', {
                    required:true
                  })}>
                    <option value="">Select Range</option>
                    {pdfNumberList?.map((item, i) => (
                      <option key={i} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                  {errors1.range && (
                  <div className="error_line">Range is required</div>
                )}
                </div>
              </div>
              <div className="col-md-4">
                <div>
                  <input className="btn btn-primary w-100" type="submit" value="Submit" />
                </div>
              </div>
            </div>
          </form>
        </div>
      </ModalBox>
      <ModalBox
        show={isExcelDownloadModalShow}
        hide={excelDownloadModalclose}
        title="Choose range for download Excel"
      >
        <div className="">
          <form onSubmit={handleSubmit1(onDownloadExcel)}>
            <div className="row g-3 justify-content-center">
              <div className="col-md-12">
                <div className="">
                  <select className="form-select" {...register1('range', {
                    required:true
                  })}>
                    <option value="">Select Range</option>
                    {pdfNumberList?.map((item, i) => (
                      <option key={i} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                  {errors1.range && (
                  <div className="error_line">Range is required</div>
                )}
                </div>
              </div>
              <div className="col-md-4">
                <div>
                  <input className="btn btn-primary w-100" type="submit" value="Submit" />
                </div>
              </div>
            </div>
          </form>
        </div>
      </ModalBox>
    </>
  );
};

export default HistoricalReport;
